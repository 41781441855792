
import React, { useState } from 'react'
import styled from 'styled-components'

import { SUMMARY_MODE } from '../../constants'

import BioRevealerButton from '../atoms/BioRevealerButton'

const Frame = styled.div`
  height: 500px;
  overflow:auto;
`

const Component = (props) => {
  return (
    <>
      <Frame>
        {props.items}

      </Frame>
      <div>
        <BioRevealerButton onClick={() => { props.setDisplayMode(SUMMARY_MODE) }}>Hide</BioRevealerButton>
      </div>
    </>
  )
}

export default Component
