import React from 'react';
import DoughnutChart from './doughnut';
import BarsChart from './bar';
import Map from './map';
import Heatmap from './heatmap';
import DonutChart from './pie';
import CustomBarChart from './customBar';
import Line, { LineChartModel } from './lineChart';
import PolarAreaChart from './polarArea';
// CONSTANTS
import { colorPallets } from '../../constants';
// STYLES
import './chart.scoped.scss';
import StackBar from './stackbar';

interface ChartComponentModel {
    type: string;
    data: any;
    valueKeys?: Array<string> | Array<{
        key: string;
        barColor: string;
    }>;
    XYKeys?: Array<string | any>;
    XLables?: Array<string>;
    YLables?: Array<string>;
    layout?: string;
    aspect?: number;
    colors?: Array<string>;
    title?: { xaxis: string, yaxis: string };
    isAxis?: { x: boolean, y: boolean };
    height?: string;
    classes?: string;
    angledTick?: boolean;
    legendStyle?: any;
    chartSliceClick?: Function;
    multiLabel?: string[] | any[];
    strokeWidth?: number;
    background?: string;
    forground?: string;
}

const ChartComponent = ({
    type,
    data,
    valueKeys = [],
    XYKeys = [],
    XLables = [],
    YLables = [],
    layout = 'horizontal',
    aspect = 2,
    colors = colorPallets,
    title = { xaxis: '', yaxis: '' },
    isAxis = { x: true, y: true },
    height = '',
    classes = '',
    angledTick = false,
    legendStyle = { bottom: -10 },
    chartSliceClick,
    multiLabel,
    strokeWidth,
    background,
    forground
}: ChartComponentModel) => {

    switch (type) {
        case 'doughnut':
            return <DoughnutChart chartData={data} />;

        case 'bars':
            return <BarsChart legendStyle={legendStyle}
                angledTick={angledTick}
                classes={classes}
                colors={colors}
                data={data}
                aspect={aspect}
                valueKeys={valueKeys}
                XYKeys={XYKeys}
                XLabels={XLables}
                YLabels={YLables}
                layout={layout}
                multiLabel={multiLabel}
                background={background}
                forground={forground}
            />;
        
        case 'stackbar':
            return <StackBar data={data} chartheading={XLables[0]} layout={layout} background="#FFF" aspect={aspect} forground={forground}/>;

        case 'map':
            return <Map data={data} />;
        
        case 'heatmap':
            return <Heatmap data={data} />;
        
        case 'donut':

        case 'pie':
            return <DonutChart classes={classes} data={data} colors={colors} type={type} />;

        case 'multiStackPercentageBar':

        case 'negativeBar':
            return <CustomBarChart 
                barType={type} 
                data={data} 
                colors={colors} 
                title={title} 
                XLabels={XLables} 
                isAxis={isAxis} 
                height={height} 
            />;

        case 'polarArea':
            return <PolarAreaChart 
                classes={classes} 
                data={data} 
                colors={colors} 
                chartSliceClick={chartSliceClick} 
            />;

        case 'line':
            return <Line data={data} xLables={XLables} strokeWidth={strokeWidth} forground={forground} background={background}></Line>

        default:
            return <></>;
    }
}

export default ChartComponent;
