import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { getUserClaims } from '../../config/service';
// ELEMENTS
import UserProfileTab from './elements/userProfileTab';
import TeamsTab from './elements/teamsTab';
import AccountsTab from './elements/accountsTab';
// STYLES
import './profile.scoped.scss';

const { TabPane } = Tabs;

const ProfilePage = ({

}) => {
  const [user, setUser]: any = useState({});
  useEffect(() => {
    const userData = getUserClaims();
    if (userData) {
      setUser(userData);
    }
  }, []);

  const callback = (key) => {
  };

  return (
    <div className="profile-page-wrapper">
      <Tabs size="large" defaultActiveKey="1" onChange={callback}>
        <TabPane tab="User Profile" key="userProfile">
          <UserProfileTab />
        </TabPane>
        <TabPane tab="Team" key="team">
          <TeamsTab />
        </TabPane>
        <TabPane tab="Account" key="account">
          <AccountsTab />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ProfilePage;
