import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Layout, Spin } from 'antd';
import HeaderComponent from './Header';
// STYLES
import './style.scoped.scss';

const { Content } = Layout;

interface LayoutProps {
  menues?: Array<Object>
  children: any,
  logout: any,
  loading: any
}

const LayoutComponent = ({
  menues,
  logout,
  children,
  loading,
}: LayoutProps) => (
  <Spin className="spinner" spinning={loading.loading !== loading.totalRequest}>
    <Layout className="layout-wrapper">
      <HeaderComponent
        menues={menues}
        logout={logout}
      />
      <Content
        className="site-layout-background"
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
        }}
      >
        {children}
      </Content>
    </Layout>
  </Spin>
);

const mapStateToProps = (state) => ({
  loading: state.loading,
});

export default connect(mapStateToProps)(LayoutComponent);
