
/*
 * Utility function that takes the API Chart Data and iterates through it to build out our ranges
 * for the y-scale for the chart.  This wouldn't normally be necessary, but nivo's chart package has
 * some quirks with this, and it makes sense for us to just figure it out and send in the data.
 *
 * Params:
 * @chartData is an an array with {id: 'Twitter', data:[..{}..]}
 *
 * Return:
 * @scaleRange is an object with `sourceRanges` the range for each source channel (meant for inspection/debugging) and `aggregateRange`
 * which is the range for all sources combined.  AggregateRange is what is ultimately handed to the chart.
 */
function extractRangeFromData (chartData) {
  // our return value
  const scaleRange = { sourceRanges: {}, aggregateRange: {} }
  const aggregateRangeSet = { min: null, max: null }
  chartData.forEach(sourceChannel => {
    // console.log('logSource ', sourceChannel)
    // each `sourceChannel` has a `id` identifier and `data:[]`
    const rangeSet = { min: 0, max: 0, id: sourceChannel.id }
    let min, max
    sourceChannel.data.forEach(dataRow => {
      // console.log(dataRow)
      // Set the local sourceRange
      if (!min) {
        min = dataRow.y
      } else if (dataRow.y < min) {
        min = dataRow.y
      }
      if (!max) {
        max = dataRow.y
      } else if (dataRow.y > max) {
        max = dataRow.y
      }

      // set and check for the aggregateRange
      if (aggregateRangeSet.min === null) {
        aggregateRangeSet.min = dataRow.y
      } else if (dataRow.y < aggregateRangeSet.min) {
        aggregateRangeSet.min = dataRow.y
      }
      if (aggregateRangeSet.max === null) {
        aggregateRangeSet.max = dataRow.y
      } else if (dataRow.y > aggregateRangeSet.max) {
        aggregateRangeSet.max = dataRow.y
      }
    })
    rangeSet.min = min
    rangeSet.max = max

    scaleRange.sourceRanges[sourceChannel.id] = rangeSet
  })
  scaleRange.aggregateRange = aggregateRangeSet
  return scaleRange
}

/*
 * PieChart Marshalling for Charts
 *
 */

// data should be {a18_29:"32", "a30_44":"14", a45_60:"0", a60_plus:"2"}
function demographicAgePieChart (data) {
  const chartData = []

  for (const [key, value] of Object.entries(data)) {
    // console.log(`${key}: ${value}`)
    const chartableDataValue = {
      // what gets used as the `legend`
      id: '',
      // Determines the shape/region of the cell in pie-chart and can be referenced in tool-tip
      value: '',
      // if enabled will display on the actualy pie-chart region
      label: ''
    }
    if (key === 'a18_29') {
      chartableDataValue.id = '18-29'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = '18-29 Years Old'
    }
    if (key === 'a30_44') {
      chartableDataValue.id = '30-44'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = '30-44 Years Old'
    }
    if (key === 'a45_60') {
      chartableDataValue.id = '45-60'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = '45-60 Years Old'
    }
    if (key === 'a60_plus') {
      chartableDataValue.id = 'Over 60'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = '60+ Years Old'
    }
    chartData.push(chartableDataValue)
  }
  return chartData
}

// data should be {male:"32.233", female:"72.334"}
function demographicGenderPieChart (data) {
  const chartData = []

  for (const [key, value] of Object.entries(data)) {
    // console.log(`${key}: ${value}`)
    const chartableDataValue = {
      // what gets used as the `legend`
      id: '',
      // Determines the shape/region of the cell in pie-chart and can be referenced in tool-tip
      value: '',
      // if enabled will display on the actualy pie-chart region
      label: ''
    }
    if (key === 'male') {
      chartableDataValue.id = 'Male'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Male'
    }
    if (key === 'female') {
      chartableDataValue.id = 'Female'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Female'
    }
    chartData.push(chartableDataValue)
  }
  return chartData
}

// data should be {asian:"32.233", asian_pacific_islander:"72.334", black: "23.4", white: "33", hispanic: "23"}
function demographicEthnicityPieChart (data) {
  const chartData = []

  for (const [key, value] of Object.entries(data)) {
    // console.log(`${key}: ${value}`)
    const chartableDataValue = {
      // what gets used as the `legend`
      id: '',
      // Determines the shape/region of the cell in pie-chart and can be referenced in tool-tip
      value: '',
      // if enabled will display on the actualy pie-chart region
      label: ''
    }
    if (key === 'asian') {
      chartableDataValue.id = 'Asian'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Asian'
    }
    if (key === 'asian_pacific_islander') {
      chartableDataValue.id = 'Pacific Islander'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Pacific Islander'
    }
    if (key === 'black') {
      chartableDataValue.id = 'Black'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Black'
    }
    if (key === 'white') {
      chartableDataValue.id = 'White'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'White'
    }
    if (key === 'hispanic') {
      chartableDataValue.id = 'Hispanic'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Hispanic'
    }
    chartData.push(chartableDataValue)
  }
  return chartData
}

// data should be {east_north_central:"32.233", east_south_central:"72.334", middle_atlantic: '', mountain: '', west_south_central:'', new_england:'', pacific: '', }
function demographicLocationPieChart (data) {
  const chartData = []

  for (const [key, value] of Object.entries(data)) {
    // console.log(`${key}: ${value}`)
    const chartableDataValue = {
      // what gets used as the `legend`
      id: '',
      // Determines the shape/region of the cell in pie-chart and can be referenced in tool-tip
      value: '',
      // if enabled will display on the actualy pie-chart region
      label: ''
    }
    if (key === 'east_north_central') {
      chartableDataValue.id = 'East North Central'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'East North Central'
    }
    if (key === 'east_south_central') {
      chartableDataValue.id = 'East South Central'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'East South Central'
    }
    if (key === 'middle_atlantic') {
      chartableDataValue.id = 'Middle Atlantic'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Middle Atlantic'
    }
    if (key === 'mountain') {
      chartableDataValue.id = 'Mountain'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Mountain'
    }
    if (key === 'west_south_central') {
      chartableDataValue.id = 'West South Central'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'West South Central'
    }
    if (key === 'new_england') {
      chartableDataValue.id = 'New England'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'New England'
    }
    if (key === 'pacific') {
      chartableDataValue.id = 'Pacific'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Pacific'
    }
    chartData.push(chartableDataValue)
  }
  return chartData
}

// data should be {k_25_50, k_50_100, k_100_150, k_under_25, k_150_plus}
function demographicIncomePieChart (data) {
  const chartData = []

  for (const [key, value] of Object.entries(data)) {
    // console.log(`${key}: ${value}`)
    const chartableDataValue = {
      // what gets used as the `legend`
      id: '',
      // Determines the shape/region of the cell in pie-chart and can be referenced in tool-tip
      value: '',
      // if enabled will display on the actualy pie-chart region
      label: ''
    }
    if (key === 'k_under_25') {
      chartableDataValue.id = 'Under 25K'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Under 25K'
    }
    if (key === 'k_25_50') {
      chartableDataValue.id = '25K-50K'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = '25K-50K'
    }
    if (key === 'k_50_100') {
      chartableDataValue.id = '50K-100K'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = '50K-100K'
    }
    if (key === 'k_100_150') {
      chartableDataValue.id = '100K-150K'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = '100K-150K'
    }
    if (key === 'k_150_plus') {
      chartableDataValue.id = 'Over 150K'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Over 150K'
    }
    chartData.push(chartableDataValue)
  }
  return chartData
}

// data should be {bachelor_degree, graduate_degree, high_school_degree, less_than_highschool_degree, some_college_or_associate_degree}
function demographicEducationPieChart (data) {
  const chartData = []

  for (const [key, value] of Object.entries(data)) {
    // console.log(`${key}: ${value}`)
    const chartableDataValue = {
      // what gets used as the `legend`
      id: '',
      // Determines the shape/region of the cell in pie-chart and can be referenced in tool-tip
      value: '',
      // if enabled will display on the actualy pie-chart region
      label: ''
    }
    if (key === 'bachelor_degree') {
      chartableDataValue.id = 'Bachelor Degree'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Bachelor Degree'
    }
    if (key === 'graduate_degree') {
      chartableDataValue.id = 'Graduate Degree'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Graduate Degree'
    }
    if (key === 'high_school_degree') {
      chartableDataValue.id = 'High School Degree'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'High School Degree'
    }
    if (key === 'less_than_highschool_degree') {
      chartableDataValue.id = 'Less Than High School Degree'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Less Than High School Degree'
    }
    if (key === 'some_college_or_associate_degree') {
      chartableDataValue.id = 'Associate Degree'
      chartableDataValue.value = Math.round(value)
      chartableDataValue.label = 'Associate Degree'
    }
    chartData.push(chartableDataValue)
  }
  return chartData
}

export {
  extractRangeFromData,
  demographicGenderPieChart,
  demographicAgePieChart,
  demographicEthnicityPieChart,
  demographicLocationPieChart,
  demographicEducationPieChart,
  demographicIncomePieChart
}
