const multiStackedBarChart = (XLabels, isAxis) => ({
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    stackType: '100%',
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  stroke: {
    width: 1,
    colors: ['#fff'],
  },
  yaxis: {
    show: isAxis.y,
  },
  xaxis: {
    show: isAxis.x,
    categories: XLabels,
  },
  tooltip: {
    y: {
      formatter(val) {
        return `${val}%`;
      },
    },
  },
  fill: {
    opacity: 1,

  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    offsetX: 40,
  },
});

const negativeBarChart = (title, XLabels, colors) => ({
  chart: {
    type: 'bar',
    stacked: true,
  },
  colors: colors && colors.length > 0 ? colors : ['#008FFB', '#FF4560'],
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '80%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 1,
    colors: ['#fff'],
  },

  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    min: -5,
    max: 5,
    title: {
      text: title.yaxis,
    },
  },
  tooltip: {
    shared: false,
    x: {
      formatter(val) {
        return val;
      },
    },
    y: {
      formatter(val) {
        return `${Math.abs(val)}%`;
      },
    },
  },
  xaxis: {
    categories: XLabels,
    title: {
      text: title.xaxis,
    },
    labels: {
      formatter(val) {
        return `${Math.abs(Math.round(val))}%`;
      },
    },
  },
});

const returnApexBarChartOptions = (barType, title, XLabels, colors, isAxis) => {
  switch (barType) {
    case 'negativeBar':
      return negativeBarChart(title, XLabels, colors);
    case 'multiStackPercentageBar':
      return multiStackedBarChart(XLabels, isAxis);
    default:
      return {};
  }
};

export {
  returnApexBarChartOptions,
};
