
import React, { useState } from 'react'
import styled from 'styled-components'

import { ALL_MODE } from '../../constants'
import BioRevealerButton from '../atoms/BioRevealerButton'

const Component = (props) => {
  return (
    <>
      {props.items}
      <div>
        <BioRevealerButton onClick={() => { props.setDisplayMode(ALL_MODE) }}>Read More</BioRevealerButton>
      </div>
    </>
  )
}

export default Component
