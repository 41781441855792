import React, { useEffect, useState } from 'react';
import _ from 'lodash';
// UTILS
import * as functionMapper from './functionMapper';

// COMPONENTS
import ChartComponent from '../Chart/chart';
// STYLES
import './comparision.scoped.scss';
import { Col, Row } from 'antd';
import RadarChart from '../Chart/radarChart';

const ComparisionChartComponent = ({
    data
}) => {
    const [comparisionChartData] = useState([]);
    const [, updateState]:any = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
    }, [data]);

    useEffect(() => {
        setTimeout(forceUpdate, 1000);
    }, [comparisionChartData]);

    return (<div>
        {
            data.map((section, index) => (<div key={index} className="section">
                <div className='section-header'>
                    <h3 className='text-center'>{ section.name }</h3>
                    <h5 className='text-center'>{ section.description }</h5>
                </div>

                <div className='chart-section'>
                    <Row style={{width: '100%'}} gutter={10}>
                    {
                        section.data.map((chartObj, chartIndex) => render(chartObj, chartIndex))
                    }
                    </Row>       
                </div>
                
            </div>))
        }
    </div>
    );

};

function render(chartObj, chartIndex) {
    if (functionMapper[chartObj.mapperFunctionName]) {
        let chartsData = functionMapper[chartObj.mapperFunctionName](chartObj, chartIndex);
        return _.map(chartsData, (chartData, chartKey) => (
            <Col key={chartKey} sm={{span:chartData.width}} xs={{span: 24}} offset={chartData.offset || 0}>
                <div className={'chart'}>
                { selectChart(chartData.data, chartObj, chartIndex, chartKey, chartData.aspect) }
                </div>
            </Col>
        ));
    }

    return <div></div>
}

function selectChart(chartData, chartObj, chartIndex, chartKey, aspect) {
    switch (chartObj.type) {
        case 'bar': 
            return <ChartComponent key={chartIndex+chartKey}
            type="bars"
            data={chartData.data}
            valueKeys={chartData.valueKeys}
            XYKeys={['name', null]}
            XLables={[chartObj.name]}
            colors={[]}
        />

        case 'line': 
           return <ChartComponent key={chartIndex+chartKey}
            data={chartData}
            type={"line"}
            forground={"#FFF"}
            background={"#0a0909"}
            XLables={[chartObj.name]}
        />

        case 'pie':
            return <ChartComponent key={chartIndex+chartKey}
                data={chartData.data}
                type={"pie"}
                XLables={[chartData.headerName]}
            />
        case 'stackbar':
            return <ChartComponent key={chartIndex+chartKey}
                data={chartData}
                type={"stackbar"}
                XLables={[chartObj.name]}
                layout="vertical"
                aspect={aspect}
                background={"#111"}
                forground={"#FFF"}
                />

        case 'radar':
            return <RadarChart key={chartIndex+chartKey} 
                altColor={chartData.altColor} 
                data={chartData.data} 
                headerName={chartData.headerName}/> 
    }
    return <div>No Data</div>
}

export default ComparisionChartComponent;