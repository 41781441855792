import React, { useState, useEffect } from "react";
import { Select, Tag } from "antd";
// COMPONENTS
import TagComponent from "../../components/Tag";
import ComparisionChartComponent from "../../components/ComparisionChart";
// SERVICES
import { getArtists } from "../../services/artists";
// UTILS
import { createComparisionDataForArtist } from "./utils";
// STYLES
import "./compare.scoped.scss";

import pdfRndr from "./../../components/utils/pdfRender";
import PdfContainer from "./../../components/utils/PdfContainer";

const { Option } = Select;

const ComparePage = ({}) => {
  const [options, setOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [selectedArtists, setSelectedArtistOptions] = useState([]);
  const [artistComparisonData, setArtistComparisonData] = useState([]);

  const [alreadySelected, setAlreadySelected] = useState(false);

  const fetchArtists = async () => {
    const artistsList = await getArtists();
    setOptions(artistsList || []);
  };

  useEffect(() => {
    fetchArtists();
  }, []);

  const toggleAddNewTagDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeTag = (removedTag) => {
    const filteredTags = tags.filter((tag) => tag !== removedTag);
    const filterSelectedOptions = selectedArtists.filter(
      (obj) => obj.name !== removedTag
    );
    setTags(filteredTags);
    setSelectedArtistOptions(filterSelectedOptions);
    const comparisionChartObj = createComparisionDataForArtist(
      filterSelectedOptions
    );
    setArtistComparisonData(comparisionChartObj);
  };

  const selectArtistFromMenu = async (value: string, option: any) => {
    if (!tags.includes(option.value)) {
      setTags([...tags, option.value]);
      toggleAddNewTagDropdown();
      const artistData = await getArtists({}, option.key);

      const artistComparisonData = createComparisionDataForArtist([
        ...selectedArtists,
        artistData,
      ]);
      setSelectedArtistOptions([...selectedArtists, artistData]);
      setArtistComparisonData(artistComparisonData);
      setAlreadySelected(false);
    } else {
      setAlreadySelected(true);
    }
  };
  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const selectStyle = () => {
    if (alreadySelected) {
      return { width: 200, border: "1px solid red" };
    }
    return { width: 200, border: "1px solid blue" };
  };
  const createPdf = async (html) => {
    let artistNames;
    document.getElementById("addBtn").style.visibility = "hidden";
    selectedArtists.forEach((elems) => {
        artistNames = elems.name+'vs'+artistNames
    });
    artistNames = artistNames.replace("vsundefined",'')
    artistNames = artistNames.replace(" ",'_')
    await pdfRndr.createPdf(html,artistNames);
    sleep(1000).then(() => {
      document.getElementById("addBtn").style.visibility = "visible";
    });
  };

  return (
    <div className="compare-wrapper">
      <PdfContainer createPdf={createPdf}>
        {tags.map((tag, idx) => (
          <TagComponent key={tag} tag={tag} close={closeTag} />
        ))}
        {dropdownVisible && (
          <Select
            placeholder="Select artist"
            style={selectStyle()}
            onChange={selectArtistFromMenu}
            optionFilterProp="name"
            showSearch
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options.length > 0 &&
              options.map((artist) => (
                <Option key={artist.id} value={artist.name}>
                  {artist.name}
                </Option>
              ))}
          </Select>
        )}
        {alreadySelected && (
          <p style={{ color: "#a77" }}>Artist is alreasy selected</p>
        )}
        {!dropdownVisible && (
          <Tag
            className="add-tag"
            id="addBtn"
            onClick={toggleAddNewTagDropdown}
          >
            + Add
          </Tag>
        )}

        <ComparisionChartComponent data={artistComparisonData} />
      </PdfContainer>
    </div>
  );
};

export default ComparePage;
