import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Row, Col } from 'antd';
import './chart.scoped.scss';
import '../../pages/artists/artists.scoped.scss';
import _ from 'lodash';

/**
 *
 * Usage:
    <ChartComponent data={[40,60]} type='pie' colors={['#F44336', '#E91E63']} />
 *
 */

interface PieChartProps {
    type: any;
    data: {
        label: string;
        value: number;
    }[];
    colors: string[];
    classes: string;
}

const PieChart = ({
  type,
  data,
  colors,
  classes,
}: PieChartProps) => {
  const [donutValues, setDonutValues] = useState([]);
  const [options, setOptions]: any = useState({
    legend: {
      show: false,
      fontSize: '20px',
    },
    chart: {
      width: 380,
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  });

  useEffect(() => {
    const values = _.map(data, 'value');
    const labels = _.map(data, 'label');
    setDonutValues(values);
    setOptions({
      ...options,
      ...(labels.length > 0 && {
        legend: {
          show: true,
          customLegendItems: labels,
          markers: {
            fillColors: colors,
          },
          // position: 'bottom'
        },
      }),
      ...(labels.length > 0 && {
        tooltip: {
          style: {
            padding: '10px',
          },
          custom({ series, seriesIndex }) {
            return `<div style="padding: 5px 15px">${labels[seriesIndex]}: ${series[seriesIndex]}</div>`;
          },
        },
      }),
      ...(colors.length > 0 && {
        fill: {
          colors,
        },
      }),
    });
    // }
  }, [data]);
  return (
    <div className={`pie-wrapper ${classes}`}>
      {/* <Row align='middle'>
                <Col>
                    <div className='flex'>
                        <div className='custom-legend-dot' style={{background: colors.length > 0 ? colors[1] : '#00e396'}}></div>
                        <div className='legend-txt'>{data[1]} %</div>
                    </div>
                </Col>
                <Col> */}
      <Chart
        options={options}
        series={donutValues}
        type={type}
      />
      {/* </Col>
                <Col>
                    <div className='flex'>
                        <div className='custom-legend-dot' style={{background: colors.length > 0 ? colors[0] : '#008ffb'}}></div>
                        <div className='legend-txt'>{data[0]} %</div>
                    </div>
                </Col>
            </Row> */}
    </div>
  );
};

export default PieChart;
