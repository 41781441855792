import React from 'react';
import { Tag } from 'antd';

const TagComponent = ({
    tag,
    close=null
}) => {
    const tagElem = (
        <Tag
            closable
            onClose={e => {
                e.preventDefault();
                close && close(tag);
            }}
        >
            {tag}
        </Tag>
    );
    return (
        <span key={tag} style={{ display: 'inline-block' }}>
            {tagElem}
        </span>
    );
};

export default TagComponent;