import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import {savePDF} from '@progress/kendo-react-pdf'

class pdfRender {
    printDocument = async (elems) => {
         htmlToImage
           .toPng(elems, { quality: 0.95 })
           .then(function(dataUrl) {
             var link = document.createElement("a");
             link.download = "my-image-name.jpeg";
             const pdf = new jsPDF();
             const imgProps = pdf.getImageProperties(dataUrl);
             console.log(imgProps);
     
             const pdfWidth = pdf.internal.pageSize.getWidth();
             const pdfHeight = pdf.internal.pageSize.getHeight();
     
             // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
             pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
             pdf.save("download.pdf");
           });

           
     };

     
    createPdf = (html,pdfFileName) => {
        savePDF(html, { 
          scale: 0.4,
          paperSize: 'A4',
          fileName: `${pdfFileName}.pdf`,
          margin: 3
        })
      }
}

const pdfRndr = new pdfRender();
export default pdfRndr;