import React, { useState } from 'react'
import styled from 'styled-components'

import { generateSnapshot, renderSnapshotValue } from '../../../libs/audienceSnapshotUtils'

const AudienceFrame = styled.div`
background-color: #fff;
width: 380px;
height: 200px;
display:flex;
text-align:center;
padding-top:2%;
color: #000;
flex-direction:column;
border:1px solid #333;
`

const Header = styled.h3`
  font-weight:bold;
  text-align:left;
  padding: 0 1em;
  font-size: 1.3em;
`

const MetricFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 .2em;
  width: 340px;
  margin: 0 1em;
`

const MetricBox = styled.div`
  display: flex;
  flex: 0 0 32%;
  
`
const MetricList = styled.dl`
margin: 0;
padding: 0;
width: 100%;

`

const MetricTitle = styled.dt`
 font-weight: bold;
 text-align:left;
 padding:.6em 0 0 0;
`

const MetricValue = styled.dd`
text-align:left;
font-size: .9em;
padding: 0;
margin: 0;
`

const Component = (props) => {
  // console.log('--ff', props)
  // data has the `demographics` object from api
  // we can process it internally
  const snapshot = generateSnapshot(props.data)
  // debug to see snapshot
  // console.log('--audienceSnapshot', snapshot)

  return (
    <AudienceFrame>
      <Header>Audience Snapshot</Header>
      <MetricFrame>
        <MetricBox>
          <MetricList>
            <MetricTitle>Age</MetricTitle>
            <MetricValue>{renderSnapshotValue('age', snapshot)}</MetricValue>
            <MetricTitle>Gender</MetricTitle>
            <MetricValue>{renderSnapshotValue('gender', snapshot)}</MetricValue>
          </MetricList>
        </MetricBox>
        <MetricBox>
          <MetricList>
            <MetricTitle>Ethnicity</MetricTitle>
            <MetricValue>{renderSnapshotValue('ethnicity', snapshot)}</MetricValue>
            <MetricTitle>Income</MetricTitle>
            <MetricValue>{renderSnapshotValue('income', snapshot)}</MetricValue>
          </MetricList>
        </MetricBox>
        <MetricBox>
          <MetricList>
            <MetricTitle>Location</MetricTitle>
            <MetricValue>{renderSnapshotValue('location', snapshot)}</MetricValue>
            <MetricTitle>Education</MetricTitle>
            <MetricValue>{renderSnapshotValue('education', snapshot)}</MetricValue>
          </MetricList>
        </MetricBox>

      </MetricFrame>

    </AudienceFrame>
  )
}

export default Component
