import styled from 'styled-components'

import { PRIMARY_DARK_BLUE } from '../../constants'

const BioRevealerButton = styled.button`
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #efefef;
  color: ${PRIMARY_DARK_BLUE};
  
`

export default BioRevealerButton
