import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { HEADER_GREY } from '../../constants'
// Get our useAuth context so we can access the apiToken
import { useAuth } from '../../../contexts/auth0-context'
// import { getApiDomain } from '../../../libs/utils'

// ATOMS
import PanelHeader from '../atoms/PanelHeader'
import SpotifyIcon from '../atoms/icons/SpotifyIcon'
import TwitterIcon from '../atoms/icons/TwitterIcon'
import YoutubeIcon from '../atoms/icons/YoutubeIcon'

// Artists
import ArtistBio from '../../artists/organisms/ArtistBio'
import AudienceSnapshot from '../../artists/organisms/AudienceSnapshot'
import AudienceDemographic from '../../artists/organisms/AudienceDemographic'
import TotalFollowersByChannel from '../../artists/organisms/TotalFollowersByChannel'
import DailyReach from '../../artists/organisms/DailyReach'

import bandImage from '../../../assets/autobahn.jpg'
import bandBGTile from '../../../assets/band-bg.jpg'
import noAvatarImage from '../../../assets/avatar-image.png'

// Format
import { format } from 'friendly-numbers'

// Load Fixtures
import BANDS from '../../../fixtures/bands'

/*
 * Notes: Recommend leaving the FlexBox layout templates here for now, instead of dropping into Core
 *        Think we will use these potentially in a Brands profile page an in that case we should make a
 *        `profiles` atomic-design folder and organize these there and rename.
 */
const BandFrame = styled.div`
  display: flex;
  padding-top:3.5rem;
  flex-grow:1;
  text-align:center;
  /* set at 150px in height consider this in the padding-top to not be rems */
  background:url(${bandBGTile}) repeat-x;
`

const BandPanel = styled.div`
  display: flex;
  margin: 0 auto;
  width: 90%;
  //border: 1px dashed #222;
  flex-direction: column;

`

const BandAvatarImage = styled.img`
  display: flex; 
  width: 200px;
  height: 200px;
  clip-path: circle(50% at 50% 50%);
  //border:3px solid #000;
  max-width: 200px;
  margin-left:20px;

`

/* TODO
   To apply a border need to do a css trick
   https://stackoverflow.com/questions/31854185/how-to-add-border-in-my-clip-path-polygon-css-style
   NOT connected yet..
*/
const BandBorderImage = styled.div`
  display:flex;
  width: 200px;
  max-width: 200px;
  background-color: #ccc;
  border: 1px dotted #000;
`

/* top section contains band avatar, header, and social followers */
const BandMetaInfo = styled.div`
  flex-direction: row;
  display:flex;
  text-align:left;
  padding-bottom: 2em;
`

/* Main Body info positioning piece will contain bio and audience panel
 2-cols
 */
const BandMainInfo = styled.div`
  text-align:left;
  flex-basis: 100%;
  flex-grow: 1;
  display:flex;
  flex-direction: row;

`

/* Need a template-frame to position the HEader and Social counts from the image */
const BandHeaderAndSocialCount = styled.div`
  display:flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 60%;
  margin-left: 2em;
`

/* Need a panel container to position social accounts away from header */
const SocialCountsPanel = styled.dl`
  color: #000;
  padding-top: .50em;
`

const BandHeader = styled.h2`
  color: #fff;
  font-size: 2em;
  padding:1em 0 .5em 0;
  font-family: Roboto;
`

const SocialIcon = styled.dt`
 float:left;
 padding: 0 .25em 0 0;
 width: 26px;
`

const SocialCount = styled.dd`
  float:left;
  padding-right: 2em;
`

const BandProfileInfo = styled.div`
  display:flex;
  flex-direction: column;
  //border:1px dashed #18ADEE;
  flex-grow: 1;
  flex-basis: 60%;
`

function BandProfileView (props) {
  // const { isLoading } = props;
  // Get our Band ID from url
  // mismatch here till app is adjusted, so name is currently an integer
  const bandId = props.match.params.name

  const [selectedBand, setBand] = useState({ name: 'Unknown', bio: 'N/A' })
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const apiToken = user.__raw;
    // const API_DOMAIN = getApiDomain()
    const API_DOMAIN = config.api.prod;
    const url = `${API_DOMAIN}/artists/${bandId}`
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        authorization: 'Bearer ' + apiToken
      }

    }

    const fixtureData = {
      name: 'Radiohead',
      bio: "At some point in the early 21st century, Radiohead became something more than a band: they became a touchstone for everything that is fearless and adventurous in rock, inheriting the throne from David Bowie, Pink Floyd, and the Talking Heads. The latter group gave the band its name -- it's an album track on 1986's True Stories -- but Radiohead never sounded much like the Heads, nor did they take much from Bowie, apart from their willingness to experiment.",
      img: 'https://yt3.ggpht.com/a/AATXAJy9Z8-L6BevH_zhec86JhvfAnOg-NoTuEfpzQ=s288-c-k-c0xffffffff-no-rj-mo',
      popularity: 42,
      totalPlays: 193,

      twitter: 72312,
      spotify: 141693,
      youtube: 484712,

      // Stub in reporting fixtures -- allow for
      total_followers: { data: [] },
      reach: { data: [] }
    }
    // setBand(fixtureData)

    fetch(url, options).then((response) => {
      return response.json()
    }).then((data) => {
      console.log('payload:', data)
      setBand(data)
    })
  }, []) // < send ARRAY IN HERE FOR Effect to run once on Mount

  let bandAvatarImage
  if (selectedBand.img) {
    console.log('have? ', selectedBand.img)
    bandAvatarImage = selectedBand.img
  } else {
    console.log(noAvatarImage)
    bandAvatarImage = noAvatarImage
  }

  return (
    <BandFrame>

      <BandPanel>
        <BandMetaInfo>

          <BandAvatarImage src={bandAvatarImage} />

          <BandHeaderAndSocialCount>
            <BandHeader>{selectedBand.name}</BandHeader>
            <SocialCountsPanel>

              <SocialIcon><SpotifyIcon fill={HEADER_GREY} /></SocialIcon>
              <SocialCount>{format(selectedBand.spotify, { formattedDecimals: 2 })}</SocialCount>

              <SocialIcon><TwitterIcon fill={HEADER_GREY} /></SocialIcon>
              <SocialCount>{format(selectedBand.twitter, { formattedDecimals: 2 })}</SocialCount>

              <SocialIcon><YoutubeIcon fill={HEADER_GREY} /></SocialIcon>
              <SocialCount>{format(selectedBand.youtube, { formattedDecimals: 2 })}</SocialCount>

            </SocialCountsPanel>

          </BandHeaderAndSocialCount>

        </BandMetaInfo>

        <BandMainInfo>
          <BandProfileInfo>
            <ArtistBio bio={selectedBand.bio} />
          </BandProfileInfo>

          <AudienceSnapshot data={selectedBand.demographics} />

        </BandMainInfo>

        <AudienceDemographic data={selectedBand.demographics} />

        <TotalFollowersByChannel data={selectedBand.total_followers} />
        <DailyReach data={selectedBand.reach} />
      </BandPanel>

    </BandFrame>
  )
}

export default BandProfileView
