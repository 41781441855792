import React, { useState, useEffect } from 'react';
import {
  Row, Col, Dropdown, Select, Button, Menu, Divider, AutoComplete, Card
} from 'antd';
import { withRouter } from 'react-router-dom';
// UTILS
import { mapArtistBrandAffinitiesData } from './utils';
// ELEMENTS
import ChartElement from './elements/chartElement';
// SERVICES
import { getArtists, getBrands } from '../../services/dashboard';
import { getArtistsBrandAffinities } from '../../services/artists';
// COMPONENTS
import ChartComponent from '../../components/Chart/chart';
// STYLES
import './dashboard.scoped.scss';

const { Option } = Select;

const DashboardPage = ({
  history,
}) => {
  // STATES
  const [options, setOptions] = useState([]);
  const [artistOptions, setArtistOptions] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [selectedSecondBrand, setSelectedSecondBrand] = useState({});
  const [artistBrandAffinitiesData, setArtistBrandAffinitiesData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const fetchBrands = async () => {
    const brandsList = await getBrands();
    setBrandsOptions(brandsList || []);
  };

  const fetchArtists = async () => {
    const artistsList = await getArtists();
    setArtistOptions(artistsList || []);
  };

  useEffect(() => {
    fetchBrands();
    fetchArtists();
  }, []);

  const onSearch = async (searchText: string) => {
    try {
      if (searchText) {
        const artists = await getArtists({ q: searchText });
        if (artists) {
          const mapArtistsResult = artists.map((artist: any) => ({
            ...artist,
            label: artist.name,
            value: artist.name,
          }));
          setOptions(mapArtistsResult);
        }
      }
    } catch (err) {
      return err;
    }
  };

  const onSelect = (txt: string, data: any) => {
  };

  const selectArtistFromMenu = async (value: string, option: any) => {
    const artistBrandAffinitiesRes = await getArtistsBrandAffinities(option.key);
    const mappedArtistBrandAffnitiesResult = mapArtistBrandAffinitiesData(artistBrandAffinitiesRes);
    setArtistBrandAffinitiesData(mappedArtistBrandAffnitiesResult);
  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a href="">1st menu item</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="">2nd menu item</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
  );

  const handleDropdown = (e: any, type: string) => {
    e.preventDefault();
  };

  const selectBrandType = (value: string, option: any) => {
    setSelectedBrand(option);
  };

  const selectSecondBrandType = (value: string, option: any) => {
    setSelectedSecondBrand(option);
  };

  const affinityChartSliceClick = (selectedSliceData) => {
    history.push(`/reports/${selectedSliceData.id}`);
  }

  const submitSearch = () => {
    setIsSubmit(true);
  };

  return (
    <div className="dashboard-wrapper">
      <div className="search-brands-wrapper">
        <Row>
          <Col span={24}>
            <div className="title">Search Brands</div>
          </Col>
          <Col span={24}>
            <Dropdown className="saved-search-list" overlay={menu} trigger={['click']}>
              <Button onClick={(e) => handleDropdown(e, 'savedSearchList')}>
                Saved Search List
                <span className="material-icons">expand_more</span>
              </Button>
            </Dropdown>
          </Col>
          <Divider />
        </Row>
        <Row justify="space-between">
          <Col>
            <AutoComplete
              options={options}
              style={{ width: 200 }}
              onSelect={onSelect}
              onSearch={onSearch}
              placeholder="Bring me the horizon"
              notFoundContent="Not Found"
            />
          </Col>
          <Col>
            <div className="txt">Affinity to</div>
          </Col>
          <Col>
            <Select
              placeholder="Brand Type"
              style={{ width: 120 }}
              onChange={selectBrandType}
              optionFilterProp="value"
              showSearch
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {brandsOptions.length > 0 && brandsOptions.map((brand) => <Option key={brand.id} value={brand.name}>{brand.name}</Option>)}
            </Select>
          </Col>
          <Col>
            <div className="txt">And/Or</div>
          </Col>
          <Col>
            <Select
              placeholder="Brand Type"
              style={{ width: 200 }}
              onChange={selectSecondBrandType}
              optionFilterProp="value"
              showSearch
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {brandsOptions.length > 0 && brandsOptions.map((brand) => <Option key={brand.id} value={brand.name}>{brand.name}</Option>)}
            </Select>
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col>
            <Button className="submit-search" onClick={submitSearch}>Submit Search</Button>
          </Col>
        </Row>
        <Row justify="center" className="advanced-search">
          <Col>
            <span onClick={() => { }}>Advanced Search</span>
          </Col>
        </Row>
      </div>
      <br />
      <div className='artist-affinity-wrapper'>
        <Select
          placeholder='Select artist'
          style={{ width: 200 }}
          onChange={selectArtistFromMenu}
          optionFilterProp='name'
          showSearch
          filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {artistOptions.length > 0 && artistOptions.map(artist => <Option key={artist.id} value={artist.name}>{artist.name}</Option>)}
        </Select>
        {artistBrandAffinitiesData.length > 0 && <Col span={12}>
            <Card hoverable>
                <div style={{fontSize: '25px'}}>Brand Affinities</div>
                <ChartComponent classes='affinity-chart' data={artistBrandAffinitiesData} type='polarArea' chartSliceClick={affinityChartSliceClick} />    
            </Card>    
        </Col>}
      </div>
      <br />
      {isSubmit && (
        <div className="affinities-wrapper">
          <Row>
            <Col span={24}>
              <div className="title">Recommended Affinities to Bring Me The Horizon</div>
            </Col>
            <Col span={24} />
          </Row>
          <ChartElement type="doughnut" />

        </div>
      )}
    </div>
  );
};

export default withRouter(DashboardPage);
