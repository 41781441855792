import React, { useState } from 'react'
import styled from 'styled-components'

import { SUMMARY_MODE } from '../../constants'

import BioParagraph from '../atoms/BioParagraph'
import BioFull from '../molecules/BioFull'
import BioSummary from '../molecules/BioSummary'

const ArtistBioHeader = styled.h3`
font-size: 1.5em;
display:block;
font-family: Roboto;
`

/* make a div for now, when we get data from API we will need to wrapper it into p tags */
const ArtistBioBlock = styled.div`
font-size: 1.2em;
display:block;
`

const ArtistBio = (props) => {
  // Set our State for SummaryMode
  const [stateDisplayMode, setDisplayMode] = useState(SUMMARY_MODE)

  // console.log('check bio', props.bio)

  // Inline React Wrappers
  const BioEmptyText = () => { return (<><BioParagraph>This artist currently doesn't have a bio in the cuttime platform.  Stay tuned.</BioParagraph></>) }

  // 1. Check if Bio is empty, if so render the <BioEmptyText
  // If we have a bio, We should understand if the component has been tasked with rendering a summary or displaying all
  // 1. Split it up, by new lines so we can place each block into a `<p>` tag
  // 2. If in Summary mode show the BioSummary component
  // 3. If in all mode show the entire BioFull

  // 1. Set our default empty, plan to override if bio set
  let BioBlockElements = <BioEmptyText />

  if (props.bio) {
    // invoke the splilt on newlines, we need this no matter what we do next
    const splitBio = props.bio.split(/\r?\n/g)
    // CHECK if MORE was selected to render everything
    // Default to summary mode
    if (stateDisplayMode === SUMMARY_MODE) {
      // for summary mode, we can get complex here.  but for now lets be simple
      // 1. Check how many blocks, if only 1, then this makes things simple...
      // 2. If there are more than 1 block, we should check to see size of blocks to understand how much to show
      //    using a character length -- deferring atm as a followup.
      const paragraphsElements = [<BioParagraph>{splitBio[0]}</BioParagraph>]

      BioBlockElements = <BioSummary items={paragraphsElements} setDisplayMode={setDisplayMode} />
    // RENDER THE WHOLE THING
    } else {
      // console.log(splitBio)
      const paragraphsElements = splitBio.map((item) => {
        // make sure the newline entry is great than 0, can have a trailing or empty newline
        if (item.length > 0) {
          return <BioParagraph>{item}</BioParagraph>
        }
      })
      BioBlockElements = <BioFull items={paragraphsElements} setDisplayMode={setDisplayMode} />
    }
  }

  return (
    <>
      <ArtistBioHeader>Bio</ArtistBioHeader>
      <ArtistBioBlock>
        {BioBlockElements}
      </ArtistBioBlock>
    </>
  )
}

export default ArtistBio
