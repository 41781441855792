import react from 'react';
import ChartComponent from '../../latest/components/Chart/chart';

export const mockIncommingData = {
    xAxisData : [
        "Fun", "Angry", "Sad"
    ],
    yAxisData: [
        {
            name: "Ramesh",
            data: [
                {
                    xData: "Fun",
                    yData: 89
                },
                {
                    xData: "Angry",
                    yData: 49
                },
                {
                    xData: "Sad",
                    yData: 19
                }
            ]
        },
        {
            name: "Suresh",
            data: [
                {
                    xData: "Fun",
                    yData: 79
                },
                {
                    xData: "Angry",
                    yData: 41
                },
                {
                    xData: "Sad",
                    yData: 11
                }
            ]
        }
    ]
}

const TestPage = () => {
    return <div style={{width: "100%"}}>
        <h1>Test Componet</h1>
        <div style={{width:"500px", height:"400px", margin:"20px auto"}}>
            <ChartComponent data={mockIncommingData} type="stackbar"/>
        </div>
    </div>
}


export default TestPage;