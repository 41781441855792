import { AUDIENCE_DEMOGRAPHICS_LABEL_MAP } from '../components/constants'
/*
 * Takes the artist-detail.demographic data-object from the API and marshalls it into
 * and audience snapshot.
 */

export const generateSnapshot = (snapshotData) => {
  // console.log('api-data', snapshotData)

  const snapshotContext = {
    age: 'N/A',
    ethnicity: 'N/A',
    income: 'N/A',
    gender: 'N/A',
    education: 'N/A',
    location: 'N/A'
  }
  if (!snapshotData) {
    return snapshotContext
  }
  const audienceDemographics = ['gender', 'income', 'education', 'age', 'location', 'ethnicity']
  // iterate over an object/keys
  for (const [key, sourceRecord] of Object.entries(snapshotData)) {
    // filter out they keys that we dont want to parse (source comes in here from api)
    if (audienceDemographics.includes(key)) {
      let snapshotLabel = ''
      let maxValue = 0
      // for each source-pobj we plan to parse get the highest value
      for (const [rangeKey, value] of Object.entries(sourceRecord)) {
        if (value > maxValue) {
          // set this because it is the higest
          snapshotLabel = rangeKey
          // update our maxValue to current value
          maxValue = value
        }
      }
      // great bugger to see the filtering-calc set for creating snapshot
      // console.log('-', key, '--> ', snapshotLabel, ' @ ', maxValue)
      snapshotContext[key] = snapshotLabel
    }
  }
  return snapshotContext
}

export const renderSnapshotValue = (mapKey = '', snapshot = null) => {
  // default to N/A
  let snapshotValue = 'N/A'
  // If these params aren't set return N/A
  if (!mapKey || !snapshot) {
    return snapshotValue
  }
  // Do a lookup
  const valueToLookup = snapshot[mapKey]
  if (valueToLookup === 'N/A') {
    // return the default snapshot value
    return snapshotValue
  }
  // could write a better check here to see if keys exist
  if (AUDIENCE_DEMOGRAPHICS_LABEL_MAP[mapKey][valueToLookup]) {
    snapshotValue = AUDIENCE_DEMOGRAPHICS_LABEL_MAP[mapKey][valueToLookup]
  }
  return snapshotValue
}
