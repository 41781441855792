import React, { useState } from 'react';
import {
  Table, Row, Col, Button, Divider, Modal, Input,
} from 'antd';
import moment from 'moment';
// STYLES
import '../profile.scoped.scss';

const TeamsTab = ({

}) => {
  const defaultMemberFields = {
    key: '',
    memberName: '',
    designation: '',
    email: '',
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addNewMemberFields, setAddNewMemberFields] = useState(defaultMemberFields);

  const [dataSource, setDataSource] = useState([
    {
      key: '1',
      memberName: 'Mike',
      designation: 'Dev',
      email: 'abc@gmail.com',
    },
    {
      key: '2',
      memberName: 'Nike',
      designation: 'Manager',
      email: 'xyz@gmail.com',
    },
  ]);

  const columns = [
    {
      title: 'Member name',
      dataIndex: 'memberName',
      key: 'memberName',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
  ];

  const toggleModal = () => {
    setAddNewMemberFields(defaultMemberFields);
    setIsModalVisible(!isModalVisible);
  };

  const memberFieldsInput = (e, type) => {
    setAddNewMemberFields({
      ...addNewMemberFields,
      [type]: e.target.value,
    });
  };

  const handleOk = () => {
    setDataSource([
      ...dataSource,
      { ...addNewMemberFields, key: String(moment().unix()) },
    ]);
    toggleModal();
  };
  return (
    <div className="teams-tab">
      <Row>
        <Col span={12}>
          <div data-testid="team-txt" className="team-txt">Your Team</div>
        </Col>
        <Col span={12}>
          <Button data-testid="member-btn" type="primary" className="member-btn" onClick={toggleModal}>Add Member</Button>
        </Col>
        <Divider />
        <Col span={24}>
          <Table dataSource={dataSource} columns={columns} />
        </Col>
      </Row>
      <Modal title="Add member" visible={isModalVisible} onOk={handleOk} onCancel={toggleModal}>
        <label data-testid="member-name-txt">Member Name:</label>
        <Input data-testid="member-name-input" value={addNewMemberFields.memberName} onChange={(e) => memberFieldsInput(e, 'memberName')} />
        <label>Designation:</label>
        <Input data-testid="designation-input" value={addNewMemberFields.designation} onChange={(e) => memberFieldsInput(e, 'designation')} />
        <label>Email Address:</label>
        <Input data-testid="email-input" value={addNewMemberFields.email} onChange={(e) => memberFieldsInput(e, 'email')} />
      </Modal>
    </div>
  );
};

export default TeamsTab;
