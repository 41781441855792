import _ from 'lodash';
import { capitalizeFirstLetter } from '../../utils';
import {
    ActivitiesModel,
    AgeModel,
    ArtistModel,
    EducationModel,
    EthnicityModel,
    GenderModel,
    IncomeModel,
    LocationModel,
    PersonalityModel,
    PersonalityTraitsModel,
    ArchTypesModel,
    MappedActivitiesDataModel,
    MappedAgeDataModel,
    MappedEthnicityDataModel,
    MappedGenderDataModel,
    MappedIncomeDataModel,
    MappedLocationDataModel,
    MappedPersonalityDataModel,
    MappedEducationDataModel,
    MappedPersonalityTraitsDataModel,
    MappedArchTypesModel
} from './artistModel';
import { archetypes } from '../../constants';

const modifyLabelString = (labelString: string) => {
  if (labelString.includes('plus') || labelString.includes('under')) {
    labelString = labelString.replace('_', ' ');
  } else {
    labelString = labelString.replace('_', ' - ');
  }
  return labelString;
};

const mapAgeData = (ageData: AgeModel) => _.map(ageData, (value, key) => {
  let updateAgeLabelStr = key.replace('a', '');
  updateAgeLabelStr = modifyLabelString(updateAgeLabelStr);
  return {
    name: updateAgeLabelStr,
    value,
  };
});

const mapGenderData = (genderData: GenderModel) => _.map(genderData, (value, key) => ({
  name: capitalizeFirstLetter(key),
  value,
}));

const mapIncomeData = (incomeData: IncomeModel) => _.map(incomeData, (value, key) => {
  let updateLabelStr = key.replace('k', '');
  updateLabelStr = updateLabelStr.replace('_', '');
  updateLabelStr = modifyLabelString(updateLabelStr);
  updateLabelStr = updateLabelStr.replace(' - ', 'k - $');
  if (updateLabelStr.includes('plus')) {
    updateLabelStr = updateLabelStr.replace(' plus', 'k plus');
    updateLabelStr = `$${updateLabelStr}`;
  } else if (updateLabelStr.includes('under')) {
    updateLabelStr = updateLabelStr.replace('under ', 'under $');
    updateLabelStr = `${updateLabelStr}k`;
  } else {
    updateLabelStr = `$${updateLabelStr}k`;
  }
  return {
    label: updateLabelStr,
    value: Number(Number(value).toFixed(2)),
  };
});

const mapEthnicityData = (ethnicityData: EthnicityModel) => _.map(ethnicityData, (value, key) => ({
  label: capitalizeFirstLetter(key),
  value: Number(Number(value).toFixed(2)),
}));

const mapPersonalityData = (personalityData: PersonalityModel) => _.map(personalityData, (value, key) => ({
  name: capitalizeFirstLetter(key.replace(/[_]/g, ' ')),
  value,
}));

const mapPersonalityTraitsData = (personalityTraitsData: PersonalityTraitsModel) => {
    return _.map(personalityTraitsData, (value, key) => ({
        name: capitalizeFirstLetter(key.replace(/[_]/g, ' ')),
        value
    }));
};

const mapActivitiesData = (activitiesData: ActivitiesModel) => {
    return _.map(activitiesData, (value, key) => ({
        name: capitalizeFirstLetter(key.replace(/[_]/g, ' ')),
        value
    }));
};

const mapEducationData = (educationData: EducationModel) => _.map(educationData, (value, key) => ({
  name: capitalizeFirstLetter(key.replace(/[_]/g, ' ')),
  value,
}));

const mapLocationData = (locationData: LocationModel) => _.map(locationData, (value, key) => {
  const updateLocationLabel = capitalizeFirstLetter(key.replace(/[_]/g, ' '));
  return {
    label: updateLocationLabel,
    value: Number(Number(value.popularity).toFixed(2)),
  };
});

const mapFollowerGrowthData = (totalFollowers): any => {
  interface LineChartModel {
    xAxisData: Array<string>;
    yAxisData: Array<LineData>;
  }
  
  interface LineData {
    name: string;
    data: Array<{xAxis: string, yAxis:number}>;
  }
  const result:LineChartModel = {
    xAxisData: [],
    yAxisData: [],
  }

  //xSxisData Mapping logic
  const socialMedia = ['spotify', "youtube", "twitter"]
  
  socialMedia.forEach( (sm, index) => {
    result.yAxisData.push({ name: sm, data: [] })
    totalFollowers[sm].map((item) => {
      if(!result.xAxisData.includes(item.date))
        result.xAxisData.push(item.date)
      result.yAxisData[index].data.push({xAxis: item.date, yAxis: item.value})
    })
  })
  
  result.xAxisData.sort()
  return [result];
}
const mapArchTypesData = (archTypes: ArchTypesModel[]) => _.map(archTypes, (data:ArchTypesModel) => ({
  label: data.name,
  value: data.value
}));

const mapArtistData = (artistData: ArtistModel) => {
    const ageData: MappedAgeDataModel[] = mapAgeData(artistData.demographics.age);
    const genderData: MappedGenderDataModel[] = mapGenderData(artistData.demographics.gender);
    const incomeData: MappedIncomeDataModel[] = mapIncomeData(artistData.demographics.income);
    const ethnicityData: MappedEthnicityDataModel[] = mapEthnicityData(artistData.demographics.ethnicity);
    const personalityData: MappedPersonalityDataModel[] = mapPersonalityData(artistData.demographics.personality);
    const personalityTraitsData: MappedPersonalityTraitsDataModel[] = mapPersonalityTraitsData(artistData.demographics.personality_traits);
    const locationData: MappedLocationDataModel[] = mapLocationData(artistData.demographics.location);
    const activitiesData: MappedActivitiesDataModel[] = mapActivitiesData(artistData.demographics.activities);
    const educationData: MappedEducationDataModel[] = mapEducationData(artistData.demographics.education);
    const followerGrowthData = mapFollowerGrowthData(artistData.total_followers);
    const archTypesData: MappedArchTypesModel[] = mapArchTypesData(archetypes);
    const artistObj = {
        ...artistData,
        demographics: {
            ...artistData.demographics,
            age: ageData,
            ethnicity: ethnicityData,
            personality: personalityData,
            income: incomeData,
            gender: genderData,
            location: locationData,
            activities: activitiesData,
            education: educationData,
            personality_traits: personalityTraitsData,
            archetypes: archTypesData
        },
        total_followers: followerGrowthData,
    };
    return artistObj;
};

export {
    mapArtistData,
    mapAgeData,
    mapGenderData,
    mapIncomeData,
    mapEthnicityData,
    mapPersonalityData,
    mapLocationData,
    mapEducationData,
    mapActivitiesData,
    mapPersonalityTraitsData,
    mapFollowerGrowthData,
    mapArchTypesData
};
