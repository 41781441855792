import React from 'react'

const BRAND_COLOR = '#04ACEE'
const TwitterIcon = (props) => {
  const ICON_FILL = props.fill ? props.fill : BRAND_COLOR
  return (
    <svg viewBox='0 0 24 20'>
      <path fill={ICON_FILL} d='M8.95360602,20 C7.91577214,20 6.84754296,19.9144254 5.77290518,19.7429034 C3.8801584,19.4405026 1.96415739,18.4324378 1.42198625,18.1305963 L0,17.3389849 L1.53953904,16.8234866 C3.22244857,16.2600741 4.24618345,15.9105047 5.51344672,15.3631257 C4.24453548,14.7374432 3.26657664,13.6119231 2.79581606,12.1569689 L2.4376645,11.0499061 L2.73136343,11.0955831 C2.45286215,10.8097752 2.23295417,10.5196793 2.06248427,10.2558709 C1.45622667,9.31827935 1.13524525,8.17262409 1.20354305,7.1908469 L1.27092535,6.22454404 L1.8390973,6.44864128 C1.59941408,5.98776905 1.4261976,5.49557541 1.32439174,4.97970431 C1.07628576,3.72162748 1.28392575,2.38506075 1.90922617,1.2162873 L2.40415644,0.291186958 L3.06607767,1.10069637 C5.15950651,3.66122195 7.81103661,5.18012403 10.9580463,5.62477623 C10.8295072,4.72260769 10.92582,3.85194695 11.2446042,3.07152175 C11.6157561,2.16282786 12.2760294,1.39228382 13.1534641,0.843040434 C14.127944,0.233205092 15.3096977,-0.0622975654 16.4810144,0.0109722055 C17.7237417,0.0887164164 18.8520291,0.562825681 19.7481404,1.38296194 C20.1857591,1.26718459 20.5082054,1.1435769 20.9439931,0.976529363 C21.2061981,0.876039816 21.5035591,0.761940382 21.8756266,0.631434492 L23.2467099,0.15042707 L22.3526127,2.75047724 C22.4115722,2.74544348 22.4723627,2.74115542 22.5358998,2.73835885 L24,2.67086868 L23.1346502,3.87506513 C23.085029,3.94404684 23.0723948,3.96362271 23.0548168,3.99065607 C22.9850541,4.09767092 22.8982628,4.2307869 21.7104667,5.84570409 C21.4131057,6.2500859 21.2646083,6.77677037 21.292257,7.32899674 C21.3975418,9.42753145 21.1446751,11.3262057 20.5402486,12.9720714 C19.9685977,14.5291931 19.0827401,15.8652005 17.9075782,16.9429928 C16.4533657,18.2763901 14.5990708,19.189372 12.3959626,19.6562102 C11.3152823,19.8851548 10.1547687,20 8.95360602,20 Z' id='Path' />
    </svg>)
}

export default TwitterIcon
