import React, { useEffect } from "react";
import {
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ComposedChart,
    Legend,
    Text,
} from 'recharts';
// STYLES
import "./chart.scoped.scss";

/**
 * Example Data:
 * const data = [
        {
            name: "Page A",
            uv: 4000,
            pv: 2400,
            amt: 2400,
            fill: ''
        },
        {
            name: "Page B",
            uv: 3000,
            pv: 1398,
            amt: 2210
        },
        {
            name: "Page C",
            uv: 2000,
            pv: 9800,
            amt: 2290
        },
        {
            name: "Page D",
            uv: 2780,
            pv: 3908,
            amt: 2000
        },
        {
            name: "Page E",
            uv: 1890,
            pv: 4800,
            amt: 2181
        },
        {
            name: "Page F",
            uv: 2390,
            pv: 3800,
            amt: 2500
        },
        {
            name: "Page G",
            uv: 3490,
            pv: 4300,
            amt: 2100
        }
    ];
 * Usage:
    <ChartComponent data={data} type='bars' valueKeys={['uv', 'pv']} XYKeys={['name', null]} XLables={['Char 1', 'Chart 2']} YLables={['Y label', 'Y lable']} />
 *
 */

interface BarChartProps {
    data: Array<Array<{
        name: string;
        value: string;
    }>>;
    valueKeys: string[] | {
        key: string;
        barColor: string;
    }[];
    XYKeys: any[];
    XLabels: string[];
    YLabels: string[];
    layout: any;
    aspect?: number;
    width?: string | number;
    colors?: Array<string>;
    classes?: string;
    angledTick?: boolean;
    legendStyle?: any;
    multiLabel?: string[];
    background?: string;
    forground?: string;
}

const CustomizedXaxisTick = ({
    x,
    y,
    payload,
    angledTick
}) => {
    if (angledTick) {
        return <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                {payload.value}
            </text>
        </g>
    } else {
        return <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
    }

}

const BarsChart = ({
    data,
    valueKeys,
    XYKeys,
    XLabels,
    YLabels,
    layout,
    aspect, 
    width = '100%',
    colors,
    classes,
    angledTick,
    legendStyle,
    background="#000",
    forground="#999",
}: BarChartProps) => {
  const [barData, setBarData] = React.useState([]);


    useEffect(() => {
        const mappedData = data.map((obj, index) => ({
            ...obj,
            ...(colors.length > 0 && {fill: colors[index]}),
        }));
        setBarData(mappedData);
    }, [data]);

    return (
        <div className={classes} style={{background: background}}>
            <p className='chart-title' style={{color: forground}}>{XLabels[0]}</p>
            <ResponsiveContainer width={width} aspect={aspect}>
                <ComposedChart
                    layout={layout}
                    // width={500}
                    // height={300}
                    data={barData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    {layout === 'vertical' && <XAxis type="number" />}
                    {layout === 'vertical' && <YAxis dataKey="name" type="category" scale="band" label={{ value: YLabels[0], angle: -90, position: 'insideLeft' }} />}
                    {layout === 'horizontal' && <XAxis tick={(props) => CustomizedXaxisTick({ ...props, angledTick })} interval={0} dataKey={XYKeys[0]} />}
                    {layout === 'horizontal' && <YAxis label={{ value: YLabels[0], angle: -90, position: 'insideLeft' }} dataKey={XYKeys[1]} />}
                    <Tooltip />
                    {typeof valueKeys[0] === 'string' && barData.length > 0 &&
                        <Legend
                            wrapperStyle={legendStyle}
                            payload={barData.map(
                                (item, index) => ({
                                    id: item.name,
                                    type: "square",
                                    value: `${item.name}`,
                                    color: item.fill
                                })
                            )}
                        />
                    }
                    {typeof valueKeys[0] !== 'string' && <Legend wrapperStyle={legendStyle} />}
                    {valueKeys.map((barValue, barKey) => (
                        typeof barValue !== 'string' ? 
                            <Bar barSize={20} dataKey={barValue.key} name={barValue.key} fill={barValue.barColor ? barValue.barColor : "#50E7F0"} />
                        : 
                        <Bar key={barKey} barSize={20} dataKey={barValue} name={barValue} fill="#50E790" />
                        
                    ))}
                </ComposedChart>
            </ResponsiveContainer>
            
        </div>
    );
};

export default BarsChart;
