import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Select, Card, Divider } from "antd";
import _ from "lodash";
import ChartComponent from "../../components/Chart/chart";
// UTILS
import { mapArtistData, mapFollowerGrowthData } from "./utils";
// SERVICES
import { getArtists } from "../../services/dashboard";
// STYLES
import "./artists.scoped.scss";
import "./artists.scss";

import pdfRndr from "./../../components/utils/pdfRender";
import PdfContainer from "./../../components/utils/PdfContainer";

//MockData
import { tempBarData, multiLabel } from "./mockBarData";

const { Option } = Select;

const ArtistsPage = ({}) => {
  const [options, setOptions] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [ethnicityData, setEthnicityData] = useState([]);
  const [personalityData, setPersonalityData] = useState([]);
  const [personalityTraitsData, setPersonalityTraitsData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [activiteisData, setActivitiesData] = useState([]);
  const [archTypesData, setArchTypesData] = useState([]);
  const [followerGrowth, setFollowerGrowth] = useState([]);
  const [artistName, setArtistName] = useState("");

  const fetchArtists = async () => {
    const artistsList = await getArtists();
    setOptions(artistsList || []);
  };

  useEffect(() => {
    fetchArtists();
  }, []);

  const selectArtistFromMenu = async (value: string, option: any) => {
    const artistData = await getArtists({}, option.key);
    const mappedData = mapArtistData(artistData);
    setArtistName(artistData.name);
    setAgeData(mappedData.demographics.age);
    setGenderData(mappedData.demographics.gender);
    setIncomeData(mappedData.demographics.income);
    setEthnicityData(mappedData.demographics.ethnicity);
    setPersonalityData(mappedData.demographics.personality);
    setLocationData(mappedData.demographics.location);
    setEducationData(mappedData.demographics.education);
    setActivitiesData(mappedData.demographics.activities);
    setPersonalityTraitsData(mappedData.demographics.personality_traits);
    setFollowerGrowth(mapFollowerGrowthData(artistData.total_followers));
    setArchTypesData(mappedData.demographics.archetypes);
  };

  const printDocument = () => {
    let elems = document.getElementById("artist-data");
    pdfRndr.printDocument(elems);
  };

  const createPdf = (html) => pdfRndr.createPdf(html,artistName);

  return (
    <div className="artists-wrapeer">
      <Row>
        <div>
          <button onClick={printDocument}>Print</button>
        </div>
      </Row>
        <div id="artist-data">
          <Row>
            <Col span={24}>
              <Select
                placeholder="Select artist"
                style={{ width: 200 }}
                onChange={selectArtistFromMenu}
                optionFilterProp="name"
                showSearch
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {options.length > 0 &&
                  options.map((artist) => (
                    <Option key={artist.id} value={artist.name}>
                      {artist.name}
                    </Option>
                  ))}
              </Select>

            </Col>
            </Row>
      <PdfContainer createPdf={createPdf}>
              <Row>
            {artistName && (
              <Col span={24}>
                <div style={{ fontSize: "25px", paddingTop: "20px", color:"#FFF" }}>
                  Artist Name:
                  {artistName}
                </div>
              </Col>
            )}
          </Row>
          <Row justify="space-between">
            {ageData.length > 0 && (
              <Card className="bg-dark card-style" hoverable>
                <ChartComponent
                  type="bars"
                  layout="vertical"
                  data={ageData}
                  valueKeys={["value"]}
                  XYKeys={["name", null]}
                  XLables={["Age"]}
                />
              </Card>
            )}
            {genderData.length > 0 && (
              <Card className="bg-dark card-style" hoverable>
                <ChartComponent
                  type="bars"
                  layout="vertical"
                  data={genderData}
                  valueKeys={["value"]}
                  XYKeys={["name", null]}
                  XLables={["Gender"]}
                />
              </Card>
            )}
          </Row>
          <Row justify="space-between">
            {activiteisData.length > 0 && (
              <Card className="bg-dark card-style" hoverable>
                <ChartComponent
                  type="bars"
                  data={activiteisData}
                  valueKeys={["value"]}
                  XYKeys={["name", null]}
                  XLables={["Activities"]}
                />
              </Card>
            )}
            {educationData.length > 0 && (
              <Card className="bg-dark card-style" hoverable>
                <ChartComponent
                  type="bars"
                  data={educationData}
                  valueKeys={["value"]}
                  XYKeys={["name", null]}
                  XLables={["Education"]}
                />
              </Card>
            )}
          </Row>
          <Row>
            <Col span={24} style={{ height: "50px" }} />
            {personalityData.length > 0 && (
              <Col span={24}>
                <Card className="bg-dark" hoverable>
                  <ChartComponent
                    type="bars"
                    layout="vertical"
                    aspect={3.5}
                    data={personalityData}
                    valueKeys={["value"]}
                    XYKeys={["name", null]}
                    XLables={["Personality"]}
                  />
                </Card>
              </Col>
            )}

            <Col span={24} style={{ height: "50px" }} />
            {personalityTraitsData.length > 0 && (
              <Col span={24}>
                <Card className="bg-dark personality-traits-chart" hoverable>
                  <ChartComponent
                    angledTick={true}
                    legendStyle={{ bottom: "unset", marginTop: 20 }}
                    type="bars"
                    aspect={3.5}
                    data={personalityTraitsData}
                    valueKeys={["value"]}
                    XYKeys={["name", null]}
                    XLables={["Personality Traits"]}
                  />
                </Card>
              </Col>
            )}

            <Col span={24} style={{ height: "50px" }} />
            {followerGrowth.length > 0 && (
              <Col span={12}>
                <Card className="bg-dark" style={{ padding: "20px" }} hoverable>
                  <ChartComponent
                    data={followerGrowth[0]}
                    XLables={["Followers Trend", null]}
                    forground="#FFF"
                    type="line"
                  />
                </Card>
              </Col>
            )}

            <Col span={24} style={{ height: "50px" }} />
            {ethnicityData.length > 0 && (
              <Col span={12}>
                <Card className="bg-dark " hoverable>
                  <div style={{ fontSize: "25px" }}>Ethnicity:</div>
                  <ChartComponent data={ethnicityData} type="donut" />
                </Card>
              </Col>
            )}
            {incomeData.length > 0 && (
              <Col span={12}>
                <Card className="bg-dark" hoverable>
                  <div style={{ fontSize: "25px" }}>Income:</div>
                  <ChartComponent
                    classes="income-chart"
                    data={incomeData}
                    type="donut"
                  />
                </Card>
              </Col>
            )}
            {locationData.length > 0 && (
              <Col span={12}>
                <Card className="bg-dark " hoverable>
                  <div style={{ fontSize: "25px" }}>Location:</div>
                  <ChartComponent
                    classes="location-chart"
                    data={locationData}
                    type="pie"
                  />
                </Card>
              </Col>
            )}
            {archTypesData.length > 0 && (
              <Col span={12}>
                <Card className="bg-dark" hoverable>
                  <div style={{ fontSize: "25px" }}>Archtypes:</div>
                  <ChartComponent
                    classes="archtypes-chart"
                    data={archTypesData}
                    type="pie"
                  />
                </Card>
              </Col>
            )}
          </Row>
      </PdfContainer>
        </div>
    </div>
  );
};

export default withRouter(ArtistsPage);
