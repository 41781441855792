import React, { Component, createContext, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';

// create the context
export const Auth0Context = createContext();

export const useAuth = () => useContext(Auth0Context);

// create a provider
export class Auth0Provider extends Component {
  state = {
    auth0Client: null,
    isLoading: true,
    isAuthenticated: false,
    apiToken: '',
    user: null
  };

  config = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirect_uri: window.location.origin,
    cacheLocation: 'localstorage'
  }

  componentDidMount() {
    this.initializeAuth0();
  }

  initializeAuth0 = async () => {
    
    const auth0Client = await createAuth0Client(this.config);
    this.setState({ auth0Client });

    // check to see if they have been redirected after login
    if (window.location.search.includes('code=')) {
      return this.handleRedirectCallback();
    }

    // Make call to get our raw apiToken we need
    const idToken = await auth0Client.getIdTokenClaims()
    const isAuthenticated = await auth0Client.isAuthenticated();
    const user = isAuthenticated ? await auth0Client.getUser() : null;
    //console.log('auth check --',isAuthenticated, idToken)
    // enforce a check for apiToken, else this can blowup login page
    const apiToken = (idToken && idToken.__raw? idToken.__raw:'')
    this.setState({ isLoading: false, isAuthenticated, apiToken: apiToken, user });
  };

  // handle the authentication callback
  handleRedirectCallback = async () => {
    this.setState({ isLoading: true });
    
    await this.state.auth0Client.handleRedirectCallback();
    const user = await this.state.auth0Client.getUser();
    const idToken = await this.state.auth0Client.getIdTokenClaims()
    const apiToken = (idToken && idToken.__raw? idToken.__raw:'')
    this.setState({ user, isAuthenticated: true, apiToken: apiToken, isLoading: false });
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  render() {
    // apiToken now added to the authState context so we get it on page-load from server
    // and store it in context so we have it and aren't making multiple requests for it
    const { auth0Client, isLoading, isAuthenticated, apiToken, user } = this.state;
    const { children } = this.props;
    
    const configObject = {
      isLoading,
      isAuthenticated,
      apiToken,
      user,
      loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
      getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
      getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
      logout: (...p) => auth0Client.logout(...p)
    };

    return (
      <Auth0Context.Provider value={configObject}>
        {children}
      </Auth0Context.Provider>
    );
  }
}