import { httpReq } from '../config/service';

interface GetArtist {
    q?:string;
}

const getArtists = async (payload?:GetArtist, id = '') => {
  try {
    const httpResponse: any = await httpReq.get(id ? `/artists/${id}` : '/artists', payload);
    if (httpResponse.data) {
      return httpResponse.data;
    }
  } catch (err) {
    return err;
  }
};

const getBrands = async () => {
  try {
    const  httpResponse: any = await httpReq.get('/brands');
    if (httpResponse.data) {
      return httpResponse.data;
    }  } catch (err) {
    return err;
  }
};

export {
  getArtists,
  getBrands,
};
