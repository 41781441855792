import _ from 'lodash';
// UTILS
import { capitalizeFirstLetter } from '../../utils';
// MODELS
import {
    ArtistBrandAffinitiesModel,
    MappedArtistBrandAffinitiesModel
} from './dashboardModel';

const mapArtistBrandAffinitiesData = (artistBrandAffinitiesData: ArtistBrandAffinitiesModel[]): MappedArtistBrandAffinitiesModel[] => {
    let updatedArtistBrandAffinitiesData = [];
    if (artistBrandAffinitiesData.length > 10) {
        updatedArtistBrandAffinitiesData = artistBrandAffinitiesData.sort((a, b) => parseFloat(b.total_affinity) - parseFloat(a.total_affinity));
        updatedArtistBrandAffinitiesData = updatedArtistBrandAffinitiesData.slice(0, 10);
    }
    updatedArtistBrandAffinitiesData = _.map(updatedArtistBrandAffinitiesData, (affinityObj) => {
        return {
            id: affinityObj.brand_id,
            label: capitalizeFirstLetter(affinityObj.brand),
            value: Number(parseFloat(affinityObj.total_affinity).toFixed(2))
        };
    });
    return updatedArtistBrandAffinitiesData;
};

export {
    mapArtistBrandAffinitiesData
}