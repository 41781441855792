import { httpReq } from '../config/service';

// post method to base url
// For submitting message
// path /contact_us/
const sendMessage = async (payload) => {
    try{
        const httpResponse: any = await httpReq.post(`/contact_us`, payload);
        if (httpResponse.data) {
            return httpResponse.data;
          }    }
    catch (err) {
        return ({
            error: true,
            error_message: err
        })
    }  
}

export {sendMessage};