import styled from 'styled-components'

import { HEADER_GREY } from '../../constants'

const PanelHeader = styled.h2`
    font-size: 2em;
    font-family: Roboto-Light;
    font-weight: 100;
    padding-bottom: .5em;
    color: ${HEADER_GREY};
`

export default PanelHeader
