import React from 'react';
import {
  MapContainer, TileLayer, Marker, Popup,
} from 'react-leaflet';
import './chart.scoped.scss';

/**
 * Example Data:
 * const mapData = [
        {
            position: [20.5937, 78.9629],
            name: 'India country',
            render: (value) => <div>Name: {value.name}</div>
        }
   ];
 * Usage:
    <ChartComponent data={mapData} type='map' />
 *
 */

function Map({ data, center = { lat: 34.80746, lng: -40.4796 } }) {
  return (
    <div className="map">
      <MapContainer center={{ lat: 34.80746, lng: -40.4796 }} zoom={2}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {data && data.length > 0 && data.map((locationObj, idx) => (
          <Marker position={locationObj.position}>
            <Popup>
              {!locationObj.render && locationObj.name && locationObj.name}
              {locationObj.render && locationObj.render(locationObj)}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
}

export default Map;
