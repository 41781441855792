import React from 'react'

const BRAND_COLOR = '#FF0100'
const YoutubeIcon = (props) => {
  const ICON_FILL = props.fill ? props.fill : BRAND_COLOR
  return (
    <svg viewBox='0 0 24 17'>
      <path fill={ICON_FILL} d='M23.505416,2.66009378 C23.2288541,1.61991563 22.4182114,0.799954652 21.3901864,0.5198668 C19.5121144,0 11.9996801,0 11.9996801,0 C11.9996801,0 4.48753877,0 2.60946682,0.500156884 C1.60121712,0.779948366 0.77079907,1.62006381 0.494237191,2.66009378 C0,4.55994908 0,8.5 0,8.5 C0,8.5 0,12.459909 0.494237191,14.3399062 C0.77109202,15.3799362 1.58144176,16.1998972 2.60961329,16.479985 C4.50731409,17 11.9999731,17 11.9999731,17 C11.9999731,17 19.5121144,17 21.3901864,16.4998431 C22.4183579,16.2199034 23.2288541,15.3999425 23.505709,14.3599125 C23.9999006,12.459909 23.9999006,8.52000629 23.9999006,8.52000629 C23.9999006,8.52000629 24.019575,4.55994908 23.505416,2.66009378 L23.505416,2.66009378 Z M9.60788862,12.1399567 L9.60788862,4.86004325 L15.8548472,8.5 L9.60788862,12.1399567 Z' id='Shape' />
    </svg>)
}

export default YoutubeIcon
