import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Input, Divider,
} from 'antd';
import { connect } from 'react-redux';
import { getUserClaims } from '../../../config/service';
// STYLES
import '../profile.scoped.scss';

const UserProfileTab = ({

}) => {
  const [profileFields, setProfileFields] = useState({
    name: '',
    comapnyName: '',
    phoneNumber: null,
    email: '',
  });
  const [password, setPassword] = useState({
    new: '',
    confirm: '',
  });

  useEffect(() => {
    const userData = getUserClaims();
    if (userData) {
      setProfileFields({
        ...profileFields,
        name: userData.name,
        email: userData.email,
      });
    }
  }, []);

  const profileFieldsUpdate = (e, type) => {
    setProfileFields({
      ...profileFields,
      [type]: e.target.value,
    });
  };

  const passwordFieldUpdate = (e, type) => {
    setPassword({
      ...password,
      [type]: e.target.value,
    });
  };

  return (
    <div className="user-profile-tab">
      <Row>
        <Col span={12}>
          <div data-testid="profile-txt" className="profile-txt">Edit Profile Info</div>
        </Col>
        <Col span={12}>
          <Button type="primary" className="save-btn">Save</Button>
        </Col>
      </Row>
      <Divider />
      <div className="fields-block-one">
        <div className="name-field">
          <label>Name:</label>
          <Input value={profileFields.name} onChange={(e) => profileFieldsUpdate(e, 'name')} />
        </div>
        <div className="comapny-name-field">
          <label>Company Name:</label>
          <Input value={profileFields.comapnyName} onChange={(e) => profileFieldsUpdate(e, 'companyName')} />
        </div>
      </div>
      <div className="fields-block-two">
        <div className="phone-number-field">
          <label>Phone number:</label>
          <Input value={profileFields.phoneNumber} onChange={(e) => profileFieldsUpdate(e, 'phoneNumber')} />
        </div>
        <div className="email-field">
          <label>Email address:</label>
          <Input value={profileFields.email} onChange={(e) => profileFieldsUpdate(e, 'email')} />
        </div>
      </div>
      <Divider />
      <div className="profile-txt">Change Password</div>
      <div className="fields-block-three">
        <div className="password new-password">
          <label>New Password</label>
          <Input value={password.new} onChange={(e) => passwordFieldUpdate(e, 'new')} />
        </div>
        <div className="password">
          <label>Confirm Password</label>
          <Input value={password.confirm} onChange={(e) => passwordFieldUpdate(e, 'confirm')} />
        </div>
      </div>
    </div>
  );
};

export default UserProfileTab;
