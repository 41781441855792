import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import panelRoutesData from './latest/config/panel-routes.json';
// COMPONENTS
import LayoutComponent from './latest/components/Layout';
// PAGES
import LoginPage from './latest/pages/auth';
import DashboardPage from './latest/pages/dashboard';
import EmptyPage from './latest/pages/empty'
import BandProfileView from './components/core/views/BandProfile';
import ArtistsPage from './latest/pages/artists';
import ProfilePage from './latest/pages/profile';
import ContactPage from './latest/pages/contact';
import ReportsPage from './latest/pages/reports';
import ComparePage from './latest/pages/compare';
import TestPage from './components/testComponent/test';

const App = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getIdTokenClaims,
  } = useAuth0();

  // Savind user login in information on localstorage
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getIdTokenClaims().then((claims: any) => claims ? localStorage.setItem('user', JSON.stringify(claims)) : null);
    }
  }, [isLoading, isAuthenticated]);

  return (
    <Router>
      {!isLoading && !isAuthenticated
        && (
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <LoginPage
                  isLoading={isLoading}
                  error={error}
                  getIdTokenClaims={getIdTokenClaims}
                  isAuthenticated={isAuthenticated}
                  user={user}
                  logout={logout}
                  loginWithRedirect={loginWithRedirect}
                />
              )}
            />

            <Route
              exact
              path="/contact"
              render={() => (
                <ContactPage />
              )}
            />
            <Route path="*" render={EmptyPage} />
          </Switch>
        )}
      {!isLoading && isAuthenticated && (
        <LayoutComponent
          logout={logout}
          menues={panelRoutesData}
        >
          <Switch>
            <Route exact path="/" render={() => <DashboardPage />} />
            <Route exact path="/artists" render={() => <ArtistsPage />} />
            <Route path='/band/:name' component={BandProfileView} />
            <Route path='/profile' component={() => <ProfilePage />} />
            <Route path='/reports' component={() => <ReportsPage />} />
            <Route path='/compare' component={() => <ComparePage />} />
            <Route path='/test' component={() => <TestPage />} />
            <Route path="*" component={EmptyPage} />
          </Switch>
        </LayoutComponent>
      )}
    </Router>
  );
};

export default App;


// import React from 'react'
// // Need this right now to keep the header styled
// import 'bulma/css/bulma.css'
// import { useAuth } from './contexts/auth0-context'

// // Get our ReacRouter, which everything is bound here in app
// import { BrowserRouter as Router } from 'react-router-dom'
// // Load our routes
// import Routes from './router/routes'

// // Pages
// import AppPage from './components/core/pages/AppPage'
// import LoginPage from './components/core/pages/LoginPage'

// function App () {
//   const { isLoading, user } = useAuth()

//   return (
//     <>
//       {!isLoading && !user && (
//         <LoginPage />
//       )}
//       {!isLoading && user && (
//         <Router>
//           <AppPage>
//             <Routes />
//           </AppPage>
//         </Router>
//       )}
//     </>
//   )
// }

// export default App
