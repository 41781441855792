import React, { useState } from 'react'
import styled from 'styled-components'

// Our Wrapper Componet for the Pie chart from Nivo
import ChannelReachChart from '../molecules/ChannelReachChart'

const Frame = styled.div`
  
  display: flex;
  flex-direction: column;
  padding:2em 0 4em 0;
`

const ChartGridSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding:1em 0 1em 0;
  flex-wrap: wrap;
`

const ChartFrame = styled.div`
  display:flex;
  flex: 0 0 32%;
  width: 250px;
  height: 450px;
  //border: 1px solid #ccc;
  background-color: #fff;
  padding: 1em .5em;
  margin: 0 1em 1em 0;
  
`

const Header = styled.h2`
  font-size: 2em;
  font-family: Roboto;
  display: flex;
`

const DailyReach = (props) => {
  // handle loader here in this wrapper
  if (props.data) {
    return (
      <Frame>
        <Header>Daily Reach</Header>
        <ChannelReachChart chartData={props.data} />
      </Frame>
    )
  } else {
    return (
      <Frame><p>loading...</p></Frame>
    )
  }
}

export default DailyReach
