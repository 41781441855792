import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import './chart.scoped.scss';
import '../../pages/artists/artists.scoped.scss';
import _ from 'lodash';

/**
 * 
 * Usage:
    <ChartComponent data={[40,60]} type='polarArea' colors={['#F44336', '#E91E63']} />
 * 
 */

interface PolarAreaChartProps {
    data: {
        id: string | null;
        label: string;
        value: number;
    }[];
    colors: string[];
    classes: string;
    chartSliceClick: Function; 
}

const PolarAreaChart = ({
    data,
    colors,
    classes,
    chartSliceClick
}: PolarAreaChartProps) => {
    const [polarAreaValues, setPolarAreaValues] = useState([]);
    const [options, setOptions]: any = useState({
        legend: {
            fontSize: '20px'
        },
        chart: {
            width: 380,
            type: 'polarArea'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            colors: colors
        },
        fill: {
            opacity: 0.8
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    });

    useEffect(() => {
        const values = _.map(data, 'value');
        const labels = _.map(data, 'label');
        setPolarAreaValues(values);
        setOptions({
            ...options,
            chart: {
                ...options.chart,
                ...(chartSliceClick && {
                    events: {
                        dataPointSelection: function(event, chartContext, config) {
                            chartSliceClick(data[config.dataPointIndex]);
                        }
                    }
                })
                
            },
            ...(labels.length > 0 && {
                legend: {
                    customLegendItems: labels,
                    markers: {
                        fillColors: colors
                    },
                }
            }),
            ...(labels.length > 0 && {
                tooltip: {
                    style: {
                        padding: '10px'
                    },
                    custom: function({ series, seriesIndex}) {
                        return '<div style="padding: 5px 15px">'+labels[seriesIndex]+ ': ' +series[seriesIndex]+'</div>';
                    }
                }
            }),
            ...(colors.length > 0 && {
                fill: {
                    colors
                }
            })
        })
    }, [data]);
    return (
        <div className={`polar-area-wrapper ${classes}`}>
            <Chart
                options={options}
                series={polarAreaValues}
                type='polarArea'
            />
        </div>
    );
};

export default PolarAreaChart;