import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import './chart.scoped.scss';
import { returnApexBarChartOptions } from './barChartOptions';

/**
 * For negativeBarChart:
 * Example Data
 * const negativeBarData = [
    {
        name: 'Males',
        data: [0.4, 0.65, 0.76, 0.88, 1.5, 2.1, 2.9, 3.8, 3.9, 4.2, 4, 4.3, 4.1, 4.2, 4.5,
            3.9, 3.5, 3]
    },
    {
        name: 'Females',
        data: [-0.8, -1.05, -1.06, -1.18, -1.4, -2.2, -2.85, -3.7, -3.96, -4.22, -4.3, -4.4,
            -4.1, -4, -4.1, -3.4, -3.1, -2.8]
        }
    ];
    const negativeBarXLabels = ['85+', '80-84', '75-79', '70-74', '65-69', '60-64', '55-59', '50-54',
        '45-49', '40-44', '35-39', '30-34', '25-29', '20-24', '15-19', '10-14', '5-9', '0-4'];

    Usage:
    <ChartComponent data={negativeBarData} type='negativeBar' XLables={negativeBarXLabels} title={{xaxis: 'Xtitle', yaxis: 'Ytitle'}} />
 *
    For multiStackPercentageBar
    const series = [{
        name: 'Marine Sprite',
        data: [44, 55, 41, 37, 22, 43, 21]
    }, {
        name: 'Striking Calf',
        data: [53, 32, 33, 52, 13, 43, 32]
    }, {
        name: 'Tank Picture',
        data: [12, 17, 11, 9, 15, 11, 20]
    }, {
        name: 'Bucket Slope',
        data: [9, 7, 5, 8, 6, 9, 4]
    }, {
        name: 'Reborn Kid',
        data: [25, 12, 19, 32, 25, 24, 10]
    }];
    const XLabels = [2008, 2009, 2010, 2011, 2012, 2013, 2014]
 *
 */

interface NegativeBarChartProps {
    barType: string;
    data: {
        name: string;
        data: number[];
    }[];
    colors: string[];
    title: {
        xaxis?: string;
        yaxis?: string;
    };
    XLabels: string[];
    isAxis: {
        x?: boolean;
        y?: boolean;
    };
    height: string;
}

const NegativeBarChart = ({
  barType,
  data,
  colors,
  title,
  XLabels,
  isAxis,
  height,
}: NegativeBarChartProps) => {
  const [options, setOptions]: any = useState(returnApexBarChartOptions(barType, title, XLabels, colors, isAxis));

  useEffect(() => {
    if (barType === 'negativeBar' && colors && colors.length > 0) {
      setOptions({ ...options, colors });
    }
  }, []);

  return (
    <div className="negativebar-wrapper">
      <Chart
        options={options}
        series={data}
        type="bar"
        height={height}
      />
    </div>
  );
};

export default NegativeBarChart;
