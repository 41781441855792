import React, { useEffect, useState } from 'react';
import {
  Layout, Menu, Dropdown, Button,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { getUserClaims } from '../../../config/service';
// STYLES
import './header.scoped.scss';

const { Header } = Layout;
interface HeaderComponentProp {
  menues: any;
  logout: any;
  history?: any;
}

const HeaderComponent = ({
  menues,
  logout,
  history,
}: HeaderComponentProp) => {
  const [profilePicture, setProfilePicture] = useState('');

  useEffect(() => {
    const user = getUserClaims();
    if (user) {
      setProfilePicture(user.picture);
    }
  });

  const redirectToPath = (path) => {
    history.push(path);
  };

  const logoutClick = () => {
    logout({ returnTo: window.location.origin });
    localStorage.removeItem('user');
  };

  const handleMenuClick = (e) => {
    switch (e.key) {
      case 'edit-profile':
        return history.push('/profile');
      case 'logout':
        return logoutClick();

      default:
    }
  };

  const menu = () => (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit-profile">
        Edit profile
      </Menu.Item>
      <Menu.Item key="logout">
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <div className="logo" data-testid="logo"><p>cuttime.fm</p></div>
      <Dropdown className="logout" overlay={menu}>
        <Button shape="circle" icon={<img src={profilePicture} />} />
      </Dropdown>
      <Menu className="menues" mode="horizontal" defaultSelectedKeys={['1']}>
        {menues.map((menu: any, index: number) => (
          <Menu.Item id="menu-style" className="menu-style" key={index + 1} onClick={() => redirectToPath(menu.path)}>{menu.name}</Menu.Item>
        ))}
      </Menu>
    </Header>
  );
};

export default withRouter(HeaderComponent);
