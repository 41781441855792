import React, { useState } from 'react';
import {
  Card, Button, Layout, Menu, Row, Col, Input, Divider, Form,
} from 'antd';
import { withRouter } from 'react-router-dom';
import authRoutes from '../../config/auth.routes.json';
// STYLES
import './auth.scoped.scss';
import './auth.scss';

const { Header } = Layout;
interface LoginPageProp {
    isLoading: boolean,
    error: any,
    getIdTokenClaims: any,
    isAuthenticated: boolean,
    user: any,
    logout: any,
    loginWithRedirect: any,
    history?: any,
}

const LoginPage = ({
  isLoading,
  error,
  getIdTokenClaims,
  isAuthenticated,
  user,
  logout,
  loginWithRedirect,
  history,
}: LoginPageProp) => {
  const [signUpFields, setSignUpFields] = useState({
    artist_name: '',
    email: '',
    password: '',
    confirmPassword: '',
    manager_name: '',
  });
  if (isLoading) {
    return <div data-testid="loading-txt">Loading...</div>;
  }
  if (error) {
    return (
      <div data-testid="error-msg">
        Oops...
        {error.message}
      </div>
    );
  }

  const getTokens = async () => {
    const claims = await getIdTokenClaims();
    localStorage.setItem('user', claims);
  };

  const signUpFieldsInputHandler = (e, type) => {
    setSignUpFields({
      ...signUpFields,
      [type]: e.target.value,
    });
  };

  const signUp = () => {
    console.log("SignUp called");
    const newUserInfo = signUpFields;
    console.log(newUserInfo);
  }

  const isValid = () => {

  };

  if (isAuthenticated && user) {
    return (
      <div>
        <div data-testid="user-name">
          Hello
          {user.name}
        </div>
        <button onClick={() => getTokens()}>Tokens</button>
        <button onClick={() => logout({ returnTo: window.location.origin })}>
          Log out
        </button>
      </div>
    );
  }

  const redirectToPath = (path) => {
    if (!path.includes('login')) {
      history.push(path);
    }
  };

  const inputFieldsMapper = [
    {
      placeholder: 'Enter Artist Name',
      fontIconName: 'person',
      rules: [{ required: true, message: 'Please enter Artist name!' }],
      value: signUpFields.artist_name,
      key: 'artist_name',
    },
    {
      placeholder: 'Enter email address',
      fontIconName: 'email',
      rules: [{ required: true, message: 'Please enter email!' }],
      value: signUpFields.email,
      key: 'email',
    },
    {
      placeholder: 'Enter manager name',
      fontIconName: 'person',
      rules: [{ required: true, message: 'Please enter manager name!' }],
      value: signUpFields.manager_name,
      key: 'manager_name',
    },
    {
      placeholder: 'Enter password',
      fontIconName: 'lock',
      rules: [{ required: true, message: 'Provide password' }],
      value: signUpFields.password,
      key: 'password',
    },
    {
      placeholder: 'Re-enter password',
      fontIconName: 'lock',
      rules: [{ required: true, message: 'Confirm password' }],
      value: signUpFields.confirmPassword,
      key: 'confirmPassword',
    },
  ];

  return (
    <Layout className="auth-wrapper">
      <Header className="header" style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <div className="logo">cuttime, inc.</div>
        <Menu className="header-menu" mode="horizontal" defaultSelectedKeys={['2']}>
          {authRoutes.map((menu: any, index: number) => (
            <Menu.Item style={{ color: 'skyblue' }} key={index + 1} onClick={() => redirectToPath(menu.path)}>{menu.name}</Menu.Item>
          ))}
        </Menu>
      </Header>
      <Row className="block-row">
        <Col span={14} className="block-one">
          <div className="welcome-txt">
            <div>Welcome to</div>
            <div>Cuttime.</div>
          </div>
        </Col>
        <Col span={10} className="block-two">
          <Form className="sign-up-form" onFinish={signUp}>
            <div className="create-account-txt">CREATE AN ACCOUNT</div>
            <div className="input-fields-wrapper">
              {inputFieldsMapper.map((inputObj) => (
                <Input
                  key={inputObj.key}
                  className="input-style"
                  prefix={<span className="material-icons">{inputObj.fontIconName}</span>}
                  placeholder={inputObj.placeholder}
                  value={inputObj.value}
                  onChange={(e) => signUpFieldsInputHandler(e, inputObj.key)}
                />
              ))}
            </div>
            <div className="auth-btn-wrapper">
              <Button className="btn-style sign-up-btn">sign up</Button>
            </div>
            <div className="divider-wrapper"><Divider /></div>
            <p className="already-account-txt">already have an account?</p>
            <div className="auth-btn-wrapper">
              <Button className="btn-style login-btn" data-testid="login-btn" onClick={loginWithRedirect}>login</Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
};

export default withRouter(LoginPage);
