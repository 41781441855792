import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import ChartComponent from '../../../components/Chart/chart';
// STYLES
import '../dashboard.scoped.scss';

const ChartElement = ({
  type,
}) => {
  const [searchData, setSearchData] = useState([]);

  const submitSearchData = () => [
    {
      name: 'Vans',
      firstChart: {
        name: 'test',
        data: { name: 'test', value: 60, color: 'black' },
      },
      secondChart: {
        name: 'test',
        data: { name: 'test', value: 60, color: 'black' },
      },
    },
    {
      name: 'Urban Outfitters',
      firstChart: {
        name: 'test',
        data: { name: 'test', value: 60, color: 'black' },
      },
      secondChart: {
        name: 'test',
        data: { name: 'test', value: 60, color: 'black' },
      },
    },
    {
      name: 'Coca-Cola',
      firstChart: {
        name: 'test',
        data: { name: 'test', value: 60, color: 'black' },
      },
      secondChart: {
        name: 'test',
        data: { name: 'test', value: 60, color: 'black' },
      },
    },
  ];

  useEffect(() => {
    setSearchData(submitSearchData());
  }, []);

  return (
    <Row className="chart-element-wrapper">
      {searchData.map((data, idx) => (
        <Col span={8} key={idx}>
          <Row>
            <Col span={24} className="chart-header">{data.name}</Col>
            <Col span={12}>
              <div className="center">{data.firstChart.name}</div>
              <ChartComponent type={type} data={data.firstChart.data} />
            </Col>
            <Col span={12}>
              <div className="center">{data.secondChart.name}</div>
              <ChartComponent type={type} data={data.secondChart.data} />
            </Col>
            <Col span={24} className="center">
              <Button className="view-profile">View Profile</Button>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default ChartElement;
