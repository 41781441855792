import React, { useState } from 'react';
import {
  Row, Col, Button, Divider,
} from 'antd';
import { GoogleLogin } from 'react-google-login';
import TwitterLogin from 'react-twitter-login';
import '../profile.scoped.scss';

const AccountsTab = ({

}) => {
  const [googleSignInTxt, setGoogleSignInTxt] = useState('Connect');

  const responseGoogle = (response) => {
    if (response.accessToken) {
      setGoogleSignInTxt('Connected');
    }
  };

  const authHandler = (err, data) => {
  };

  return (
    <div className="accounts-tab">
      <Row>
        <Col span={24}>
          <div className="accounts-txt">Connect Social Accounts</div>
        </Col>
        <Divider />
        <Col span={8}>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <Button data-testid="google-btn" className="google-btn" icon={<img src="assets/icons/google-icon.png" />} onClick={renderProps.onClick} disabled={googleSignInTxt === 'Connected'}>{googleSignInTxt}</Button>
            )}
            buttonText="Login"
            scope="https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/yt-analytics.readonly"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            isSignedIn
            cookiePolicy="single_host_origin"
          />
        </Col>
        <Col span={8}>
          <TwitterLogin
            authCallback={authHandler}
            consumerKey=""
            consumerSecret=""
            children={<Button data-testid="twitter-btn" className="twitter-btn" icon={<img src="assets/icons/twitter-icon.svg" />}>Connect</Button>}
          />
        </Col>
        <Col span={8} />
      </Row>
    </div>
  );
};

export default AccountsTab;
