// React,Style -- Things
import React, { useState } from 'react'
import styled from 'styled-components'
// Visual Package
// import { Line } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import DAILY_REACH from '../../../fixtures/artists-visuals/dailyReach'

// Format
import { format } from 'friendly-numbers'

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
`

const ChartFrame = styled.div`
border:1px solid #333;
background-color: #fff;
width:100%;
display: flex;
flex-direction: column;
height: 600px;

`

const ChartHeader = styled.h3`
  display: flex;
  
  text-align:center;
  font-size: 1.4em;
  padding: .6em 0 0 0;
  margin: 0 auto;  
`

/*
 * Take dailyReach data that is in BigInt format and dateFormated reduce
 */
function transformData (data) {
  const formatUnit = 'K'
  const upperBound = 0
  const dataSet = 0
  /* Marshall code from API till me make some updates
  const dataSet = data.map(item => {
    const formattedData = format(item.view_count, { formattedDecimals: 4 })
    formatUnit = formattedData.slice(-1)
    // slice(0,-1) -- cuts just the last character
    // slice(5) -- chops off the year
    const viewCountParsed = parseFloat(formattedData.slice(0, -1))
    if (viewCountParsed > upperBound) {
      upperBound = viewCountParsed
    }
    return ({ view_count: viewCountParsed, created_at: item.created_at.slice(5) })
  })
  */
  // console.log('My format? ', formatUnit)
  /*
  if (formatUnit === 'M') {
    formatUnit = 'million'
  } else if (formatUnit === 'B') {
    formatUnit = 'billion'
  } */
  // upperbound constrained in rounding effort, we could divide by 1000, 10000, ... and then multiply to
  // do whole number rounding
  // https://stackoverflow.com/questions/43360973/round-a-number-to-nearest-thousand?noredirect=1&lq=1
  // debate here though on what we are want acheive....
  return { data: dataSet, unit: formatUnit, upperBound: Math.ceil(upperBound) }
}
function ChannelReachChart (props) {
  // Flag Flipping to load data
  // Load using a Fixture
  // const fixtureData = DAILY_REACH

  // load API data....
  const chartData = { data: props.chartData.daily }

  // const chartData = transformData(props.chartData.daily)
  // const chartData = { data: DAILY_REACH }
  console.log('chart data', chartData)
  /*
  chartData.map(data => {
    console.log('dp: ', data)
    const value = format(data.view_count, { formattedDecimals: 2 })
    console.log('view: ', value)
  })
  */

  // create a quick way to filter out a channel from being displayed
  // Also put the formatter in place and make a decible
  return (
    <Frame>
      <ChartFrame>
        <ChartHeader>Past 30 Days</ChartHeader>

        <ResponsiveBar
          data={chartData.data}

          /* view_count || reach */
          keys={['view_count']}
          /* created_at || time */
          indexBy='created_at'
          margin={{ top: 20, right: 60, bottom: 90, left: 160 }}
          padding={0.3}
          // got rid of dynamic colors, one to rule the them all
          colors='#028ee6'
          /* Took some digging to figure out how to change legend font-size
          /* https://github.com/plouc/nivo/issues/298#issuecomment-482326881 */
          theme={{
            legends: {
              text: {
                fontSize: 14
              }
            }
          }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}

          /* Reading on Bar charts on the issue, they make it clear that you should be not using AUTO especially with large numbers */
          maxValue={chartData.upperBound}

          tooltipFormat={value => (value + chartData.unit)}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Time',
            legendPosition: 'middle',
            legendOffset: 32
          }}
          axisLeft={{
            tickSize: 3,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Daily Reach',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          enableGridX
          enableGridY
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top-left',
              direction: 'column',
              justify: false,
              translateX: -120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,

              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          animate
          motionStiffness={90}
          motionDamping={15}
        />
      </ChartFrame>
    </Frame>
  )
}

export default ChannelReachChart
