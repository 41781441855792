// React,Style -- Things
import React, { useState } from 'react'
import styled from 'styled-components'
// Visual Package
// import { Line } from '@nivo/line'
import { ResponsiveLine } from '@nivo/line'
import TOTAL_FOLLOWER_GROWTH from '../../../fixtures/artists-visuals/totalFollowerGrowth'

import { extractRangeFromData } from '../../../libs/chartUtils'
// Format
import { format } from 'friendly-numbers'

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
`

const ChartFrame = styled.div`
border:1px solid #333;
background-color: #fff;
width:100%;
display: flex;
flex-direction: column;
height: 600px;
`

const ChartHeader = styled.h3`
  display: flex;
  
  text-align:center;
  font-size: 1.4em;
  padding: .6em 0 0 0;
  margin: 0 auto;
  
  
`

function selectChannel (channels, channelSelected) {
  const chartData = []
  // if(channelSelected in Objects)
  const selected = channels.filter(channel => {
    console.log('channel', channel)
    if (channelSelected === channel.id) {
      return channel
    }
  })
  return selected
}

/*
 * TODO the `fill` needs mapping
 */
const TotalFollowerByChannel = (props) => {
  /*
   * TODO, get our conversion formatter, and put it into place to reduce the variance
   * update the header of chart to show our units
   */
  // console.log('followerData: ', props)
  const fixtureData = TOTAL_FOLLOWER_GROWTH
  const SPOTIFY = 'Spotify'
  const YOUTUBE = 'YouTube'
  const selected = selectChannel(props.chartData, YOUTUBE)

  // const filteredChartData = [selected]
  // props.chartData can also be sent directly
  // console.log('shape', props.chartData)

  // write the data scrub to go through and find minx max

  // FLAG TOGGLES TO ROUTE DATA
  // send all channels
  const chartData = props.chartData
  // send a single selected channel
  // const chartData = selected
  // Send fixture data
  // const chartData = fixtureData

  // will return an object-literal with `sourceRanges:{}` and `aggregateRange:{min,max}`
  // that we can use to set out y-axis window/scale -- helps zoom in on the data
  // so we don't default to 0.
  // We don't want nivo to use `auto` because it doesn't work right :|
  const scaleRange = extractRangeFromData(chartData)

  console.log('Range:', scaleRange)
  return (
    <Frame>
      <ChartFrame>
        <ChartHeader>Last 30 Days</ChartHeader>
        <ResponsiveLine
          data={chartData}

          margin={{ top: 20, right: 20, bottom: 160, left: 160 }}
          xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            precision: 'day'
          }}
          xFormat='time:%Y-%m-%d'
          yScale={{
            // Can be linear of log,
            type: 'log',
            base: 2,
            stacked: false,
            min: scaleRange.aggregateRange.min,
            max: scaleRange.aggregateRange.max
          }}

          axisTop={null}
          axisRight={null}
          /* Took some digging to figure out how to change legend font-size
          /* https://github.com/plouc/nivo/issues/298#issuecomment-482326881 */
          theme={{
            legends: {
              text: {
                fontSize: 14
              }
            }
          }}

          axisLeft={{
            orient: 'left',
            tickSize: 5,
            // tickValues: [10000, 100000, 10000000, 100000000],
            tickPadding: 5,
            tickRotation: 0,
            legend: 'follower count',
            legendOffset: -40,
            legendPosition: 'middle'
          }}

          axisBottom={{
          // For our time axis we need this bottom tick piece
            format: '%b %d',
            tickValues: 'every 2 days',

            legendOffset: 40,
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'time',

            legendPosition: 'middle'
          }}

          colors={['#028ee6', '#ff0000']}
          lineWidth={3}
          pointSize={10}

          pointBorderWidth={3}
          pointBorderColor='#fff'

          pointLabel='y'
          pointLabelYOffset={-12}
          useMesh
          legends={[
            {
              anchor: 'top-left',
              direction: 'column',
              justify: false,
              translateX: -130,
              translateY: 2,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 200,
              itemHeight: 27,
              itemOpacity: 0.75,
              symbolSize: 18,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}

        />
      </ChartFrame>
    </Frame>

  )
}

export default TotalFollowerByChannel
