import _ from 'lodash';
import {
    ApiDataModel
} from './compareModel';


const createComparisionDataForArtist = (artistsData: ApiDataModel[]) => {
    return [
        {
            name: 'Demographics',
            description: 'Demographics information of artists', // TODO: Get correct heading from client.
            data: [
                {
                    type: 'stackbar',
                    combined: true,
                    name: 'Age',
                    mapperFunctionName: 'DemographicsStackAgeGetter',
                    data: artistsData,
                },
                {
                    type: 'stackbar',
                    combined: true,
                    name: 'Gender',
                    mapperFunctionName: 'DemographicsStackGenderGetter',
                    data: artistsData
                },
                {
                    type: 'stackbar',
                    combined: true,
                    name: 'Income',
                    mapperFunctionName: 'DemographicsStackIncomeGetter',
                    data: artistsData
                }
            ]
        },
        {
            name: 'Followers Growth',
            description: 'To show the followers growth trend for the artist on different platform', // TODO
            data: [
                {
                    type: 'line',
                    combined: true,
                    name: 'Spotify',
                    mapperFunctionName: 'FollowersSpotifyGetter',
                    data: artistsData,
                },
                {
                    type: 'line',
                    combined: true,
                    name: 'Twitter',
                    mapperFunctionName: 'FollowersTwitterGetter',
                    data: artistsData,
                },
                {
                    type: 'line',
                    combined: true,
                    name: 'Youtube',
                    mapperFunctionName: 'FollowersYoutubeGetter',
                    data: artistsData,
                },
            ]

        },
        {
            name: 'Education', //TODO: Get proper heading and description
            description: 'This information is about Education', // TODO
            data: [
                {
                    type: 'pie',
                    combined: false,
                    name: 'Education',
                    mapperFunctionName: 'EducationGetter',
                    data: artistsData,
                }
            ]
        },
        {
            name: 'Personality traits', //TODO: Get proper heading and description
            description: 'This information is about Personality Traits', // TODO
            data: [
                // {
                //     type: 'pie',
                //     combined: false,
                //     name: 'Personality traits',
                //     mapperFunctionName: 'PersonalityTraitsGetter',
                //     data: artistsData,
                // },
                {
                    type: 'radar',
                    combined: false,
                    name: 'Personality traits radar chart',
                    mapperFunctionName: 'RadialPersonalityTraitsGetter',
                    data: artistsData,
                }
            ]
        },
        // {
        //     name: 'Artist Demographics',
        //     description: 'To show the demographics information of the artist', // TODO: Get correct heading from client.
        //     data: [
        //         {
        //             type: 'bar',
        //             combined: true,
        //             name: 'Age',
        //             mapperFunctionName: 'DemographicsAgeGetter',
        //             data: artistsData,
        //         },
        //         {
        //             type: 'bar',
        //             combined: true,
        //             name: 'Gender',
        //             mapperFunctionName: 'DemographicsGenderGetter',
        //             data: artistsData
        //         },
        //         {
        //             type: 'bar',
        //             combined: true,
        //             name: 'Income',
        //             mapperFunctionName: 'DemographicsIncomeGetter',
        //             data: artistsData
        //         },
        //     ]
        // },
        
    ];
}

export {
    createComparisionDataForArtist,
}