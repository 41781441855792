// React,Style -- Things
import React, { useState } from 'react'
import styled from 'styled-components'

// Our Wrapper Componet for the Pie chart from Nivo
import DemographicChart from '../molecules/DemographicChart'

// load our data fixtures
import chartDataGender from '../../../fixtures/artists-visuals/audienceGender'
import chartDataAge from '../../../fixtures/artists-visuals/audienceAge'
import chartDataEthnicity from '../../../fixtures/artists-visuals/audienceEthnicity'
import chartDataLocation from '../../../fixtures/artists-visuals/audienceLocation'
import chartDataIncome from '../../../fixtures/artists-visuals/audienceIncome'
import chartDataEducation from '../../../fixtures/artists-visuals/audienceEducation'

const Frame = styled.div`
  
  display: flex;
  flex-direction: column;
  padding:2em 0 4em 0;
`

const ChartGridSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding:1em 0 1em 0;
  flex-wrap: wrap;
`

const ChartFrame = styled.div`
  display:flex;
  flex: 0 0 32%;
  width: 250px;
  height: 450px;
  //border: 1px solid #ccc;
  background-color: #fff;
  padding: 1em .5em;
  margin: 0 1em 1em 0;
  
`

const Header = styled.h2`
  font-size: 2em;
  font-family: Roboto;
  display: flex;
`

const AudienceDemographic = (props) => {
  console.log('demographics data', props.data)
  if (props.data) {
    return (
      <Frame>
        <Header>Audience Demographics</Header>
        <ChartGridSection>
          <ChartFrame>
            <DemographicChart chartData={props.data.gender} chartName='Gender' />
          </ChartFrame>

          <ChartFrame>
            <DemographicChart chartData={props.data.age} chartName='Age' />
          </ChartFrame>

          <ChartFrame>
            <DemographicChart chartData={props.data.ethnicity} chartName='Ethnicity' />
          </ChartFrame>

          <ChartFrame>
            <DemographicChart chartData={props.data.location} chartName='Location' />
          </ChartFrame>

          <ChartFrame>
            <DemographicChart chartData={props.data.income} chartName='Income' />
          </ChartFrame>

          <ChartFrame>
            <DemographicChart chartData={props.data.education} chartName='Education' />
          </ChartFrame>
        </ChartGridSection>

      </Frame>
    )
  } else {
    return (<Frame><p>Loading...</p></Frame>)
  }
}

export default AudienceDemographic
