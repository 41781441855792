// React,Style -- Things
import React, { useState } from 'react'
import styled from 'styled-components'
// Visual Package
import { ResponsivePie } from '@nivo/pie'

import {
  demographicGenderPieChart, demographicAgePieChart, demographicEthnicityPieChart,
  demographicIncomePieChart, demographicLocationPieChart, demographicEducationPieChart
} from '../../../libs/chartUtils'
const Frame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${props => (props.containerHeight) ? props.containerHeight : 'auto'};
`

const ChartHeader = styled.h3`
  display: flex;
  font-size: 1.7em;
  width: 98%;
  
  border-bottom: 1px solid #ccc;
`

/*
 * TODO the `fill` needs mapping
 */
const DemographicChart = (props) => {
  // depending on the demographic we are displaying, we need to marshall the data into a chart shape that is easily consumable
  let chartData = props.chartData

  // We have special helpers that will take API data and marshall it into a chartData Shape Nivo can use properly
  // Based on our Chart Rendering Options
  if (props.chartName.toLowerCase() === 'age') {
    chartData = demographicAgePieChart(props.chartData)
  }
  if (props.chartName.toLowerCase() === 'gender') {
    chartData = demographicGenderPieChart(props.chartData)
  }
  if (props.chartName.toLowerCase() === 'ethnicity') {
    chartData = demographicEthnicityPieChart(props.chartData)
  }
  if (props.chartName.toLowerCase() === 'income') {
    chartData = demographicIncomePieChart(props.chartData)
  }
  if (props.chartName.toLowerCase() === 'location') {
    chartData = demographicLocationPieChart(props.chartData)
  }
  if (props.chartName.toLowerCase() === 'education') {
    chartData = demographicEducationPieChart(props.chartData)
  }
  return (
    <Frame containerHeight={props.containerHeight}>
      <ChartHeader>{props.chartName}</ChartHeader>
      <ResponsivePie
        data={chartData}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: 'blues' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor='#333333'
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor='#333333'
        animate
        motionStiffness={90}
        motionDamping={15}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        fill={[
          {
            match: {
              id: 'male'
            },
            id: 'dots'
          },
          {
            match: {
              id: 'female'
            },
            id: 'lines'
          }
        ]}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000'
                }
              }
            ]
          }
        ]}

      />
    </Frame>

  )
}

export default DemographicChart
