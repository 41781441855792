import React, { Fragment } from 'react';

import {
    Input, Row, Col, Form, Button, Typography, Layout,
} from 'antd';

import {
    UserOutlined, MessageOutlined, InstagramOutlined, TwitterOutlined, FacebookOutlined,
} from '@ant-design/icons';

import './contact.scoped.scss';
import { sendMessage } from './../../services/sendMessage'

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Content } = Layout;

const ContactPage = () => {
    const onFinish = (values: any) => {
        sendMessage(values)
            .then(res => {
                alert("Message sent success")
            })
            .catch(err => alert(err))
    }
    return (
        <>
            <Row align="middle" className="contact-wrapper">
                <Col
                    xxl={{ span: 12 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}>

                    <Title data-testid="contact1" className="contact1 p20">Contact</Title>

                    <Form className="contact-form" onFinish={onFinish}>
                        <Form.Item
                            className="input"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your name',
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="Your full name"
                                className="input"
                                prefix={<span className="material-icons">{"person"}</span>}
                            />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            className="input"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email',
                                },
                            ]}>
                            <Input
                                size="large"
                                placeholder="Your email"
                                prefix={<span className="material-icons">{"email"}</span>}
                            />
                        </Form.Item>

                        <Form.Item
                            className="input"
                            name="message"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the message',
                                },
                            ]}>
                            <TextArea
                                size="large"
                                placeholder="Message"
                                rows={5}
                            />
                        </Form.Item>

                        <Button
                            type="primary"
                            htmlType="submit"
                            className="send"
                            id="sendButton"
                            data-testid="sendButton"
                        >
                            Send
                        </Button>
                    </Form>
                </Col>

                <Col xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}
                    className="col col2"
                >
                    <Content className="p20 information">
                        <Title className="contact2 p20">Contact</Title>

                        <Content>
                            <Text className="p20">Email: support@cuttime.fm</Text>
                            <br />
                            <Text className="p20">tel: (646) 256 4791</Text>
                            <br />
                            <Text className="p20">New York, NY</Text>

                        </Content>
                        <Content className="width-200">
                            <hr style={{ width: '100%' }} />
                            <Content className="social">
                                <InstagramOutlined style={{ fontSize: '26px' }} />
                                <TwitterOutlined style={{ fontSize: '26px' }} />
                                <FacebookOutlined style={{ fontSize: '26px' }} />
                            </Content>
                        </Content>
                    </Content>
                </Col>
            </Row>
        </>
    )
};

export default ContactPage;
