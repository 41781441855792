import _ from 'lodash';

interface StackBarChartData {
  xAxisData: Array<String>;
  yAxisData: Array<
      {
          name: String;
          data: Array<
              {
              xData: String;
              yData: Number | String;
              }
          >
      }
  > | any
}

export interface StackBarInterface {
data?: StackBarChartData;
layout?: any;
background?: any;
chartheading?: String | Array<String>;
}
const colorCodes = ['rgb(52,133,147,0.7)','rgb(131,68,140,0.7)','rgb(138,138,80,0.7)','rgb(79,255,194,0.7)','rgb(84,238,255,0.7)','rgb(251,184,35,0.7)']

const mapper = {
  'a18_29' : '18-29',
  'a30_44': '30-44',
  'a45_60': '45-60',
  'a60_plus': '60+',
  'k_25_50': '25-50k',
  'k_50_100': '50-100k',
  'k_100_150': '100-150k',
  'k_150_plus': '150k Plus',
  'k_under_25': 'Under 25k',
  'male': 'Male',
  'female': "Female",
  "bachelor_degree": "Bachelor",
  "graduate_degree": "Graduation",
  "high_school_degree": "High School",
  "less_than_high_school_degree" : "Less than high school",
  "some_college_or_associate_degree": "Some College/ Assosciate"
}

//---Functions for data mapping of Demographic data---
// This function will return age data expected by a bar-chart
export const DemographicsAgeGetter = (chartObj) => {
  return demographicsgetter(chartObj, 'age');
}

// This function will return age data expected by a bar-chart
export const DemographicsGenderGetter = (chartObj) => {
  return demographicsgetter(chartObj, 'gender');
}

// This fuction will return income data expected by a bar-chart
export const DemographicsIncomeGetter = (chartObj) => {
  return demographicsgetter(chartObj, 'income');
}


//Stack Bar Chart Mapper starts 
export const DemographicsStackAgeGetter = (chartObj) => {
  return demographicsStackGetter(chartObj, "age");
}

// This function will return gender data expected by a bar-chart
export const DemographicsStackGenderGetter = (chartObj) => {
  return demographicsStackGetter(chartObj, "gender");
}

// This function will return income data expected by a stacked bar-chart
export const DemographicsStackIncomeGetter = (chartObj) => {
  return demographicsStackGetter(chartObj, "income");
}

function demographicsStackGetter (chartObj, prop) {
  let result: StackBarChartData = {
    xAxisData: [],
    yAxisData: [],
  };
  let mapperObj = null;
  chartObj.data.forEach( artist => {
    if (artist.demographics[prop]) {
      mapperObj = artist
      return false;
    }    
  } )
  if(!mapperObj) {
    return [{data: result, width: 8, aspect: 4/chartObj.data.length} ]
  }
  _.map(mapperObj.demographics[prop], ((propValue, propKey) => {
    let res = {
      name: "",
      data: [],
    }
    let mapperValue = mapper[propKey] || propKey
    res.name= mapperValue

    chartObj.data.map((artists, artistIndex) => {
      if(!result.xAxisData.includes(artists.name)){
        result.xAxisData.push(artists.name)
      }
      if(artists.demographics[prop] && artists.demographics[prop].hasOwnProperty(propKey)){
        res.data.push({xData: artists.name, yData: artists.demographics[prop][propKey]}) 
      }
            
      })
      result.yAxisData.push(res)
    })
  );
  return [{data: result, width: 8, aspect: 4/chartObj.data.length} ];
}

//Stack bar chart mapper ends

// Education
export const EducationGetter = (chartObj) => {
  return education_personality_getter(chartObj, 'education')
}

export const PersonalityTraitsGetter = (chartObj) => {
  return education_personality_getter(chartObj, 'personality_traits')
}

export const RadialPersonalityTraitsGetter = (chartObj) => {
  return radial_education_personality_getter(chartObj, 'personality_traits')
}
// export const RadialPersonalityTraitsGetter = 

// Functions for data mapping of follower growth
export const FollowersSpotifyGetter = (chartObj) => {
  return followers_getter(chartObj, 'spotify')
}

export const FollowersTwitterGetter = (chartObj) => {
  return followers_getter(chartObj, 'twitter')
}

export const FollowersYoutubeGetter = (chartObj) => {
  return followers_getter(chartObj, 'youtube')
}


function followers_getter (chartObj, socialMedia) {
  let result = {
      xAxisData: [
    ],
      yAxisData: [
    ]
  };

  chartObj.data.forEach((artist) => {
    _.map(artist.total_followers[socialMedia], (obj, key) => {
      if (!result.xAxisData.includes(obj.date)) {
        result.xAxisData.push(obj.date);
      }

      if (!result.yAxisData.find(obj => obj.name === artist.name)) {
        result.yAxisData.push({ name: artist.name, data: [] });
      }

      let objToUpdate = result.yAxisData.find(obj => obj.name === artist.name);
      objToUpdate.data.push({
        xAxis: obj.date,
        yAxis: obj.value
      })
    });

    result.xAxisData.sort();
  });

  return [ { data: result, width: 8, aspect: 1.5 }];
 
}

function demographicsgetter(chartObj, prop) {
  let result = {
    data: [],
    valueKeys: []
  }
  chartObj.data.forEach((artist) => {
    _.map(artist.demographics[prop], (value, key) => {
      let mappedVal = mapper[key] || key
      if (!_.find(result.data, r => r.name === mappedVal)) {
        result.data.push({ name: mappedVal })
      }

      if(!result.valueKeys.find(name => name === artist.name)) {
        result.valueKeys.push(artist.name);
      }

      let obj = _.find(result.data, r => r.name === mappedVal);
      obj[artist.name] = value;
    });
  });
  return [ { data: result, width: 8, aspect: 1.5 }];
}

function education_personality_getter(chartObj, prop) {
  let artistTraitCharts = chartObj.data.map((artist) => {
    const finalResult = {
      data: {
        name: chartObj.name,
        data: _.map(
          artist.demographics[prop], (value, key) => {
          let label = mapper[key] || key;
          return {label: label, value: Number(value) }
        })
      },
      width: chartObj.data.length > 1 ? Number(24 / chartObj.data.length) : 12,
      offset: chartObj.data.length > 1 ? 0 : 6,
      aspect: 1.5
    }
    console.log(finalResult.data)
    return finalResult;
  });

  return artistTraitCharts;
}

function radial_education_personality_getter(chartObj, prop) {
  let artistTraitCharts = chartObj.data.map((artist, index) => {
    const finalResult = {
      data : {
        headerName: artist.name,
        data: _.map(
          artist.demographics[prop], (value, key: string) => {
            let label = key.charAt(0).toUpperCase() + key.slice(1);
            return {name: label, value: Number(value) }
          }),
        altColor: colorCodes[index]
      },
      width: chartObj.data.length > 1 ? Number(24 / chartObj.data.length) : 12,
      offset: chartObj.data.length > 1 ? 0 : 6,
    }
    return finalResult;
  });
  return artistTraitCharts;
}