import React from 'react';
import Chart from 'react-apexcharts';

/**
 * Example data
 * const heatmapSeries = [
    {
      name: "Series 1",
      data: [{
        x: 'W1',
        y: 22
      }, {
        x: 'W2',
        y: 29
      }, {
        x: 'W3',
        y: 13
      }, {
        x: 'W4',
        y: 32
      }]
    },
    {
      name: "Series 2",
      data: [{
        x: 'W1',
        y: 43
      }, {
        x: 'W2',
        y: 43
      }, {
        x: 'W3',
        y: 43
      }, {
        x: 'W4',
        y: 43
      }]
    }
  ]
  Usage:
    <ChartComponent data={heatmapSeries} type='heatmap' />
 */

interface HeatmapProps {
  data: {
    name: string;
    data: {
      x: string;
      y: number;
    }[];
  }[];
}

const Heatmap = ({
  data,
}: HeatmapProps) => (
  <div className="heatmap-wrapper">
    <Chart
      options={{
        chart: {
          height: 350,
          type: 'heatmap',
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#008FFB'],
      }}
      series={data}
      type="heatmap"
      width="500"
    />
  </div>
);

export default Heatmap;
