import React from 'react';
import { Radar } from '@ant-design/charts';

interface RadarChartProps {
    headerName : string,
    data : {
        name : string,
        value : number
    }[],
    altColor : string
}

const RadarChart = ({
    headerName,
    data,
    altColor
}: RadarChartProps) => {
  if(!headerName) return <div>No Header Name</div>
  data = data.filter(val => {if(val.value>0) return val});
  if(data.length<1) return <div>No Data</div>
  const config = {
    yAxis: {
        label: false,
        grid: {
          alternateColor: [null, altColor],
        },
      },
    autoFit : true,
    data : data,
    xField: 'name',
    yField: 'value',
    meta: {
        value: {
          min: 0,
          max: 100,
        },
    },
    lineStyle : {
        fill : 'white',
        fillOpacity : 0.2,
        stroke : 'white',
        lineWidth : 2,
    },
    area: {},
    point: null,
    tooltip: null,
    legend: false
  };
  return (
    <div>
      <div className='radarHeader'>
        {headerName}
      </div>
      <Radar {...config} />
    </div>
  );
};

export default RadarChart;