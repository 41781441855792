import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Card, Divider } from 'antd';
import _ from 'lodash';
// UTILS
import { mapArtistData } from './utils';
// SERVICE
import { getBrands } from '../../services/brands';
// COMPONENTS
import ChartComponent from '../../components/Chart/chart';
// STYLES
import './reports.scoped.scss';

interface ReportPageProps {
    history: any;
}

const ReportsPage = ({
    history
}: ReportPageProps) => {
    const urlPath = history.location.pathname;
    const brandId = history && urlPath.substring(urlPath.lastIndexOf('/') + 1);

    const [brandData, setBrandData]: any = useState({});
    const [ageData, setAgeData] = useState([]);
    const [genderData, setGenderData] = useState([]);
    const [incomeData, setIncomeData] = useState([]);
    const [ethnicityData, setEthnicityData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [educationData, setEducationData] = useState([]);

    const fetchBrand = async () => {
        const brandRes = await getBrands({}, brandId);
        const mappedData = mapArtistData(brandRes);
        setAgeData(mappedData.demographics.age);
        setGenderData(mappedData.demographics.gender);
        setIncomeData(mappedData.demographics.income);
        setEthnicityData(mappedData.demographics.ethnicity);
        setLocationData(mappedData.demographics.location);
        setEducationData(mappedData.demographics.education);
        setBrandData(brandRes);
    };

    useEffect(() => {
        // fetchBrand();
    }, []);

    return (
        <div>
            <h5>To be implemented</h5>
        </div>
    //     <div className='reports-wrapper'>
    //         {!_.isEmpty(brandData) && (
    //             <>
    //                 <Row>
    //                     <Col span={24}>
    //                         <div style={{ fontSize: '25px', paddingTop: '20px' }}>Brand Name: {brandData.name}</div>
    //                     </Col>
    //                 </Row>
    //                 <Row justify='space-between'>
    //                     {ageData.length > 0 &&
    //                         <Card className='card-style' hoverable>
    //                             <ChartComponent
    //                                 type='bars'
    //                                 layout='vertical'
    //                                 data={[ageData]}
    //                                 valueKeys={['value']} XYKeys={['name', null]} XLables={['Age']}
    //                             />
    //                         </Card>
    //                     }
    //                     {genderData.length > 0 &&
    //                         <Card className='card-style' hoverable>
    //                             <ChartComponent
    //                                 type='bars'
    //                                 layout='vertical'
    //                                 data={[genderData]}
    //                                 valueKeys={['value']} XYKeys={['name', null]} XLables={['Gender']}
    //                             />
    //                         </Card>
    //                     }
    //                 </Row>
    //                 {(ageData.length > 0 || genderData.length > 0) && <Divider />}
    //                 <Row>
    //                     {educationData.length > 0 && <Col span={24}>
    //                         <Card hoverable>
    //                             <ChartComponent
    //                                 type='bars'
    //                                 data={[educationData]}
    //                                 valueKeys={['value']} XYKeys={['name', null]} XLables={['Education']}
    //                             />
    //                         </Card>
    //                     </Col>}
    //                     <Col span={24} style={{ height: '50px' }} />
    //                     {ethnicityData.length > 0 && <Col span={8}>
    //                         <Card hoverable>
    //                             <div style={{ fontSize: '25px' }}>Ethnicity:</div>
    //                             <ChartComponent classes='ethnicity-chart' data={ethnicityData} type='donut' />
    //                         </Card>
    //                     </Col>}
    //                     {incomeData.length > 0 && <Col span={8}>
    //                         <Card hoverable>
    //                             <div style={{ fontSize: '25px' }}>Income:</div>
    //                             <ChartComponent classes='income-chart' data={incomeData} type='donut' />
    //                         </Card>
    //                     </Col>}
    //                     {locationData.length > 0 && <Col span={8}>
    //                         <Card hoverable>
    //                             <div style={{ fontSize: '25px' }}>Location:</div>
    //                             <ChartComponent classes='location-chart' data={locationData} type='pie' />
    //                         </Card>
    //                     </Col>}
    //                 </Row>
    //             </>
    //         )}
    //         <div />
    //     </div>
    );
};

export default withRouter(ReportsPage);