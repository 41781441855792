
// COLORS

// The Basics
export const WHITE = '#FFFFFF'
export const BLACK = '#000000'
export const APP_PANEL_BG = '#F7F9FA'
// color being used in header
export const HEADER_GREY = '#181818'
// ----
// From scaffold design guide
// ----
// label Primary-Dark
export const PRIMARY_DARK_BLUE = '#007EBB'
// label Viz - Darker
export const VIZ_DARKER = '#00528B'
// label BG-Light
export const BG_LIGHT = '#F7F9FA'
// Data Table ALT row
export const ROW_ALT = '#E0E7F2'

// Reports Detail
export const CARD_BG = '#6BDFFF'

// State Vars
export const SUMMARY_MODE = 'summary'
export const ALL_MODE = 'all'

// Audience DemoGraphics Mapping
export const AUDIENCE_DEMOGRAPHICS_LABEL_MAP = {
  age: {
    a18_29: '18-29',
    a30_44: '30-44',
    a45_60: '45-60',
    a60_plus: '60+'
  },
  education: {
    bachelor_degree: 'Bachelor',
    graduate_degree: 'Gradute',
    high_school_degree: 'High School',
    some_college_or_associate_degree: 'Associates'
  },
  ethnicity: {
    asian: 'Asian',
    asian_pacific_islander: 'Pacific',
    hispanic: 'Hispanic',
    white: 'White',
    black: 'Black'
  },
  gender: {
    male: 'Male',
    female: 'Female'
  },
  income: {
    k_25_50: '$25K - $50K',
    k_50_100: '$50 - $100K',
    k_100_150: '$100K - $150K',
    k_150_plus: '150K+',
    K_under_25: 'Under $25k'
  },
  location: {
    east_north_central: 'North East Central',
    east_south_central: 'South East Central',
    middle_atlantic: 'Middle Atlantic',
    mountain: 'Mountain',
    new_england: 'New England',
    pacific: 'Pacific',
    west_south_central: 'South West Central'
  }
}
