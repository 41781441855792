import { httpReq } from '../config/service';

interface GetArtist {
    q?:string;
}

const getArtists = async (payload?:GetArtist, id = ''):Promise<any> => {
    try{
        const httpResponse: any = await httpReq.get(id ? `/artists/${id}` : '/artists', payload);
        if (httpResponse.data) {
            return httpResponse.data;
        }

        // switch (id) {
        
        // case '1' : return {
        //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "name": "Rihanna",
        //     "bio": "string",
        //     "img": "string",
        //     "archetypes": [
        //       "string"
        //     ],
        //     "twitter": 0,
        //     "youtube": 0,
        //     "spotify": 0,
        //     "related_artists": [
        //       {
        //         "name": "string"
        //       }
        //     ],
        //     "demographics": {
        //       "source": "string",
        //       "gender": {
        //         "female": "string",
        //         "male": "string"
        //       },
        //       "ethnicity": {
        //         "asian": 0,
        //         "asian_pacific_islander": 0,
        //         "hispanic": 0,
        //         "white": 0,
        //         "black": 0
        //       },
        //       "income": {
        //         "k_under_2": 0,
        //         "k_25_50": 0,
        //         "k_50_100": 0,
        //         "k_100_150": 0,
        //         "k_150_plus": 0
        //       },
        //       "education": {
        //         "some_college_or_associate_degree": 0,
        //         "graduate_degree": 0,
        //         "bachelor_degree": 0,
        //         "high_school_degree": 0,
        //         "less_than_high_school_degree": 0
        //       },
        //       "age": {
        //         "a18_29": 0,
        //         "a30_44": 0,
        //         "a45_60": 0,
        //         "a60_plus": 0
        //       },
        //       "location": {
        //         "east_north_central": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "east_south_central": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "middle_atlantic": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "mountain": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "new_england": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "pacific": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "south_atlantic": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "west_south_central": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         }
        //       },
        //       "personality": {
        //         "alternative": 0,
        //         "urban_hip_hop": 0,
        //         "artsy": 0,
        //         "preppy": 0,
        //         "average_joe_jane\"": 0,
        //         "sporty": 0,
        //         "trendy_hipster": 0
        //       },
        //       "activities": {
        //         "outdoor_activities": 0,
        //         "cooking": 0,
        //         "crafts": 0,
        //         "traveling": 0,
        //         "fashion": 0,
        //         "playing_an_instrument": 0,
        //         "reading_books": 0,
        //         "watching_sports": 0,
        //         "individual_sports": 0,
        //         "team_sports": 0
        //       },
        //       "personality_traits": {
        //         "adventurous": 10,
        //         "aggressive": 90,
        //         "amusing": 50,
        //         "charming": 60,
        //         "conventional": 70,
        //         "depressing": 30,
        //         "dreamy": 0,
        //         "energetic": 0,
        //         "fun": 0,
        //         "happy": 0,
        //         "imaginative": 0,
        //         "inspiring": 0,
        //         "intelligent": 0,
        //         "intense": 0,
        //         "passionate": 0,
        //         "peaceful": 0,
        //         "rebellious": 0,
        //         "reliable": 0,
        //         "romantic": 0,
        //         "sensual": 90,
        //         "sophisticated": 0,
        //         "stylish": 0,
        //         "tense": 0,
        //         "tough": 0,
        //         "unique": 0,
        //         "upbeat": 0,
        //         "warm": 100,
        //         "wholesome": 0
        //       }
        //     },
        //     "reach": {
        //       "total": 0,
        //       "24h": 0,
        //       "7days": 0,
        //       "30days": 0,
        //       "daily": [
        //         {
        //           "view_count": 0,
        //           "created_at": "2021-09-15"
        //         }
        //       ]
        //     },
        //     "total_followers": {
        //       "twitter": [
        //         {
        //           "date": "string",
        //           "value": 0
        //         }
        //       ],
        //       "youtybe": [
        //         {
        //           "date": "string",
        //           "value": 0
        //         }
        //       ],
        //       "spotify": [
        //         {
        //           "date": "string",
        //           "value": 0
        //         }
        //       ]
        //     },
        //     "follower_growth": {
        //       "spotify": {
        //         "grows24h": 0,
        //         "percentage24h": 0,
        //         "grows7days": 0,
        //         "percentage7days": 0,
        //         "grows30days": 0,
        //         "percentage30days": 0
        //       },
        //       "twitter": {
        //         "grows24h": 0,
        //         "percentage24h": 0,
        //         "grows7days": 0,
        //         "percentage7days": 0,
        //         "grows30days": 0,
        //         "percentage30days": 0
        //       },
        //       "youtube": {
        //         "grows24h": 0,
        //         "percentage24h": 0,
        //         "grows7days": 0,
        //         "percentage7days": 0,
        //         "grows30days": 0,
        //         "percentage30days": 0
        //       }
        //     }
        //   }
        //   case '2' : return {
        //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "name": "Beyonce",
        //     "bio": "string",
        //     "img": "string",
        //     "archetypes": [
        //       "string"
        //     ],
        //     "twitter": 0,
        //     "youtube": 0,
        //     "spotify": 0,
        //     "related_artists": [
        //       {
        //         "name": "string"
        //       }
        //     ],
        //     "demographics": {
        //       "source": "string",
        //       "gender": {
        //         "female": "string",
        //         "male": "string"
        //       },
        //       "ethnicity": {
        //         "asian": 0,
        //         "asian_pacific_islander": 0,
        //         "hispanic": 0,
        //         "white": 0,
        //         "black": 0
        //       },
        //       "income": {
        //         "k_under_2": 0,
        //         "k_25_50": 0,
        //         "k_50_100": 0,
        //         "k_100_150": 0,
        //         "k_150_plus": 0
        //       },
        //       "education": {
        //         "some_college_or_associate_degree": 0,
        //         "graduate_degree": 0,
        //         "bachelor_degree": 0,
        //         "high_school_degree": 0,
        //         "less_than_high_school_degree": 0
        //       },
        //       "age": {
        //         "a18_29": 0,
        //         "a30_44": 0,
        //         "a45_60": 0,
        //         "a60_plus": 0
        //       },
        //       "location": {
        //         "east_north_central": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "east_south_central": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "middle_atlantic": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "mountain": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "new_england": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "pacific": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "south_atlantic": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "west_south_central": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         }
        //       },
        //       "personality": {
        //         "alternative": 0,
        //         "urban_hip_hop": 0,
        //         "artsy": 0,
        //         "preppy": 0,
        //         "average_joe_jane\"": 0,
        //         "sporty": 0,
        //         "trendy_hipster": 0
        //       },
        //       "activities": {
        //         "outdoor_activities": 0,
        //         "cooking": 0,
        //         "crafts": 0,
        //         "traveling": 0,
        //         "fashion": 0,
        //         "playing_an_instrument": 0,
        //         "reading_books": 0,
        //         "watching_sports": 0,
        //         "individual_sports": 0,
        //         "team_sports": 0
        //       },
        //       "personality_traits": {
        //         "adventurous": 50,
        //         "aggressive": 100,
        //         "amusing": 40,
        //         "charming": 90,
        //         "conventional": 80,
        //         "depressing": 40,
        //         "dreamy": 0,
        //         "energetic": 0,
        //         "fun": 0,
        //         "happy": 0,
        //         "imaginative": 0,
        //         "inspiring": 0,
        //         "intelligent": 0,
        //         "intense": 0,
        //         "passionate": 0,
        //         "peaceful": 0,
        //         "rebellious": 0,
        //         "reliable": 0,
        //         "romantic": 0,
        //         "sensual": 70,
        //         "sophisticated": 0,
        //         "stylish": 0,
        //         "tense": 0,
        //         "tough": 0,
        //         "unique": 0,
        //         "upbeat": 0,
        //         "warm": 60,
        //         "wholesome": 0
        //       }
        //     },
        //     "reach": {
        //       "total": 0,
        //       "24h": 0,
        //       "7days": 0,
        //       "30days": 0,
        //       "daily": [
        //         {
        //           "view_count": 0,
        //           "created_at": "2021-09-15"
        //         }
        //       ]
        //     },
        //     "total_followers": {
        //       "twitter": [
        //         {
        //           "date": "string",
        //           "value": 0
        //         }
        //       ],
        //       "youtybe": [
        //         {
        //           "date": "string",
        //           "value": 0
        //         }
        //       ],
        //       "spotify": [
        //         {
        //           "date": "string",
        //           "value": 0
        //         }
        //       ]
        //     },
        //     "follower_growth": {
        //       "spotify": {
        //         "grows24h": 0,
        //         "percentage24h": 0,
        //         "grows7days": 0,
        //         "percentage7days": 0,
        //         "grows30days": 0,
        //         "percentage30days": 0
        //       },
        //       "twitter": {
        //         "grows24h": 0,
        //         "percentage24h": 0,
        //         "grows7days": 0,
        //         "percentage7days": 0,
        //         "grows30days": 0,
        //         "percentage30days": 0
        //       },
        //       "youtube": {
        //         "grows24h": 0,
        //         "percentage24h": 0,
        //         "grows7days": 0,
        //         "percentage7days": 0,
        //         "grows30days": 0,
        //         "percentage30days": 0
        //       }
        //     }
        //   }
        //   case '3' : return {
        //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "name": "Justin",
        //     "bio": "string",
        //     "img": "string",
        //     "archetypes": [
        //       "string"
        //     ],
        //     "twitter": 0,
        //     "youtube": 0,
        //     "spotify": 0,
        //     "related_artists": [
        //       {
        //         "name": "string"
        //       }
        //     ],
        //     "demographics": {
        //       "source": "string",
        //       "gender": {
        //         "female": "string",
        //         "male": "string"
        //       },
        //       "ethnicity": {
        //         "asian": 0,
        //         "asian_pacific_islander": 0,
        //         "hispanic": 0,
        //         "white": 0,
        //         "black": 0
        //       },
        //       "income": {
        //         "k_under_2": 0,
        //         "k_25_50": 0,
        //         "k_50_100": 0,
        //         "k_100_150": 0,
        //         "k_150_plus": 0
        //       },
        //       "education": {
        //         "some_college_or_associate_degree": 0,
        //         "graduate_degree": 0,
        //         "bachelor_degree": 0,
        //         "high_school_degree": 0,
        //         "less_than_high_school_degree": 0
        //       },
        //       "age": {
        //         "a18_29": 0,
        //         "a30_44": 0,
        //         "a45_60": 0,
        //         "a60_plus": 0
        //       },
        //       "location": {
        //         "east_north_central": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "east_south_central": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "middle_atlantic": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "mountain": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "new_england": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "pacific": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "south_atlantic": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         },
        //         "west_south_central": {
        //           "popularity": 0,
        //           "lat": 0,
        //           "long": 0
        //         }
        //       },
        //       "personality": {
        //         "alternative": 0,
        //         "urban_hip_hop": 0,
        //         "artsy": 0,
        //         "preppy": 0,
        //         "average_joe_jane\"": 0,
        //         "sporty": 0,
        //         "trendy_hipster": 0
        //       },
        //       "activities": {
        //         "outdoor_activities": 0,
        //         "cooking": 0,
        //         "crafts": 0,
        //         "traveling": 0,
        //         "fashion": 0,
        //         "playing_an_instrument": 0,
        //         "reading_books": 0,
        //         "watching_sports": 0,
        //         "individual_sports": 0,
        //         "team_sports": 0
        //       },
        //       "personality_traits": {
        //         "adventurous": 100,
        //         "aggressive": 60,
        //         "amusing": 70,
        //         "charming": 80,
        //         "conventional": 40,
        //         "depressing": 40,
        //         "dreamy": 0,
        //         "energetic": 0,
        //         "fun": 0,
        //         "happy": 0,
        //         "imaginative": 0,
        //         "inspiring": 0,
        //         "intelligent": 0,
        //         "intense": 0,
        //         "passionate": 0,
        //         "peaceful": 0,
        //         "rebellious": 0,
        //         "reliable": 0,
        //         "romantic": 0,
        //         "sensual": 60,
        //         "sophisticated": 0,
        //         "stylish": 0,
        //         "tense": 0,
        //         "tough": 0,
        //         "unique": 0,
        //         "upbeat": 0,
        //         "warm": 100,
        //         "wholesome": 0
        //       }
        //     },
        //     "reach": {
        //       "total": 0,
        //       "24h": 0,
        //       "7days": 0,
        //       "30days": 0,
        //       "daily": [
        //         {
        //           "view_count": 0,
        //           "created_at": "2021-09-15"
        //         }
        //       ]
        //     },
        //     "total_followers": {
        //       "twitter": [
        //         {
        //           "date": "string",
        //           "value": 0
        //         }
        //       ],
        //       "youtybe": [
        //         {
        //           "date": "string",
        //           "value": 0
        //         }
        //       ],
        //       "spotify": [
        //         {
        //           "date": "string",
        //           "value": 0
        //         }
        //       ]
        //     },
        //     "follower_growth": {
        //       "spotify": {
        //         "grows24h": 0,
        //         "percentage24h": 0,
        //         "grows7days": 0,
        //         "percentage7days": 0,
        //         "grows30days": 0,
        //         "percentage30days": 0
        //       },
        //       "twitter": {
        //         "grows24h": 0,
        //         "percentage24h": 0,
        //         "grows7days": 0,
        //         "percentage7days": 0,
        //         "grows30days": 0,
        //         "percentage30days": 0
        //       },
        //       "youtube": {
        //         "grows24h": 0,
        //         "percentage24h": 0,
        //         "grows7days": 0,
        //         "percentage7days": 0,
        //         "grows30days": 0,
        //         "percentage30days": 0
        //       }
        //     }
        //   }
        // }
        // return [{id: '1', name: 'Rihanna'}, {id: '2', name: 'Beyonce'}, {id: '3', name: 'Justin'}];
    }catch(err){
        return err;
    }
};

const getArtistsBrandAffinities = async (id:string) => {
    try{
        const httpResponse: any = await httpReq.get(`/artists/${id}/brand-affinities`);
        if (httpResponse.data) {
            return httpResponse.data;
        } 
    }catch(err){
        return err;
    }
};

export {
    getArtists,
    getArtistsBrandAffinities
}
